<template>
  <div>
    <v-icon medium v-for="n in rounds" :key="n" :color="mycolor(n)">
      mdi-check-circle
    </v-icon>
  </div>
</template>
<script>
export default {
  props: {
    rounds: {
      type: Number,
      default: 0
    },
    current: { type: Number, default: 0 },
    dark: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    mycolor(n) {
      if (n == this.current) {
        if (this.dark) return 'paper '
        else return 'accent'
      } else return n <= this.current - 1 ? 'progressActive' : 'silver'
    }
  }
}
</script>
