<template>
  <div v-if="!loggedIn">
    <a href="/login" title="Login" class="progressActive--text">LOGIN</a>
    <span :class="dark ? 'silver--text' : 'charcoal--text'">
      {{ text }}
    </span>
  </div>
</template>

<script>
import { appConfig } from '@/store/helpers.js'
export default {
  computed: {
    label() {
      return this.text
    },
    ...appConfig
  },
  props: {
    text: {
      type: String,
      default: 'to SAVE your measurements'
    },
    dark: {
      type: Boolean,
      default: true
    }
  }
}
</script>
