<template>
  <v-card-text
    v-if="!is_pro && enableUpgrades"
    class="caption silver--text pa-0 mt-n1 ml-n1 top text-center"
  >
    Results are limited by the free tier.
    <a
      title="Learn More About Free Tier Restrictions. "
      class="pl-1 progressActive--text"
      @click="upgrade()"
    >
      Upgrade.
    </a>
  </v-card-text>
</template>
<script>
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  data: () => ({}),
  methods: {}
}
</script>
<style scoped>
.top {
  position: fixed;
  left: 5px;
  top: 53px;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
}
</style>
