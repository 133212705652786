<template>
  <div class="snackbar-wrapper">
    <v-snackbar
      shaped
      absolute
      :color="color"
      v-model="showSnack"
      :timeout="timeout"
    >
      <v-card-actions class="pa-3">
        <v-spacer />
        {{ snack }}
        <v-spacer />
      </v-card-actions>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  data: () => ({
    showSnack: false
  }),
  watch: {
    show: function() {
      this.showSnack = this.show
    }
  },
  props: {
    snack: {
      type: String,
      default: 'Data saved.'
    },
    color: {
      type: String,
      default: 'success'
    },
    timeout: {
      type: Number,
      default: 1000
    },
    show: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped>
.snackbar-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  pointer-events: none;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
</style>
