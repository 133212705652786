import { mapGetters } from 'vuex'
export const appConfig = {
  ...mapGetters([
    'loggedIn',
    'xvy',
    'isModalOpen',
    'userid',
    'baseURL',
    'gender',
    'sex',
    'confirmed',
    'disabled',
    'locked',
    'displayName',
    'timezone',
    'fullName',
    'profilePic'
  ])
}

export const builder = {
  ...mapGetters('builder', ['exercisesList', 'exercisesListSize'])
}
