<template>
  <v-app-bar
    app
    :height="isPhone ? 70 : 85"
    elevation="5"
    v-touch:swipe="updateApp"
    color="charcoal"
    class=" charcoalTile"
  >
    <template>
      <v-btn
        :retain-focus-on-click="false"
        :class="hamburgerClass()"
        color="transparent"
        fab
        elevation="0"
        title="Main Menu"
        @click="toggle"
      >
        <v-icon x-large color="silver">
          mdi-microsoft-xbox-controller-menu
        </v-icon>
      </v-btn>
      <template v-if="!loggedIn || !isPhone">
        <router-link to="/">
          <v-img
            :src="imageLogo"
            :width="isPhone ? '60px' : '90px'"
            contain
            title="BFit Home"
            :class="isPhone ? 'ml-n2 mt-n1' : 'ml-n2'"
          />
        </router-link>
      </template>
      <v-spacer />
      <template v-if="loggedIn">
        <span :class="isPhone ? 'mr-n5 mt-n3' : 'mr-10 pr-5 mt-n1'">
          <v-badge
            color="progressActive--text"
            offset-y="27"
            offset-x="25"
            left
            :content="numNotifications != null ? numNotifications : 0"
          >
            <v-btn
              color="transparent"
              fab
              elevation="0"
              class="btn-fix"
              title="Notifications"
              @click="toggleNotifications()"
            >
              <v-icon
                v-if="loggedIn"
                :color="hasNotifications ? 'progressActive' : 'silver'"
              >
                mdi-bell-badge-outline
              </v-icon>
            </v-btn>
          </v-badge>
        </span>
      </template>

      <div
        v-if="loaded && loggedIn && !isConfirmed"
        :class="isPhone ? 'top_notification_small' : 'top_notification'"
      >
        <v-card
          tile
          class="caption charcoal charcoalTile pt-6 pb-3"
          elevation="5"
        >
          <v-card-text class="pb-0">
            <v-row dense class="pb-2 silver--text">
              Enter the verification code received via email.
            </v-row>
          </v-card-text>
          <v-card-actions class="py-1">
            <v-otp-input
              dark
              length="6"
              v-model="vcode"
              ref="vcode"
              type="number"
              required
            />
            <v-spacer />
            <v-btn
              @click="verifyEmail"
              right
              class="ml-2  progressActive--text"
              text
              large
            >
              <span :class="textSize">
                Verify
              </span>
            </v-btn>
          </v-card-actions>
          <v-card-actions class="py-0 silver--text">
            <v-spacer />
            Need a new Code?
            <v-btn @click="resendCode" right color="progressActive" plain>
              Re-Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </template>
    <v-dialog
      v-model="showFullMenuDialog"
      scrollable
      :fullscreen="isPhone"
      overlay-opacity="0.90"
      @keydown.esc="closeMenu"
      max-width="500"
    >
      <FullMenu :show="showFullMenuDialog" @close_menu="closeMenu" />
    </v-dialog>
    <v-dialog v-model="showProWelcomeDialog" fullscreen overlay-opacity="0.90">
      <v-card
        v-touch:swipe.left="cancelPurchaseComplete"
        class="charcoal d-flex flex-column noscroll-x"
        flat
        tile
      >
        <BaseCloseDialogHeader
          heading="Purchase Complete"
          @cancel="cancelPurchaseComplete"
        />
        <v-card-text :class="textSize + ' mt-10 pt-10 text-center paper--text'">
          Welcome to
          <v-spacer />
          <span :class="textSizeLarge + ' gold--text'">
            BFITR PRO
          </span>
        </v-card-text>
        <v-card-text class="paper--text text-center">
          You can now make unrestricted use of all BFITR features. Thank you for
          joining the PRO club!
        </v-card-text>
        <v-card-text :class="textSize + ' paper--text text-center mt-10'">
          You've unlocked
        </v-card-text>
        <ul class="caption silver--text mx-5">
          <li>
            Unlimited workout templates.
          </li>
          <li>
            Unlimited programs.
          </li>
          <li>
            Unlimited food barcode scans.
          </li>
          <li>
            Unlimited Repmax log entries.
          </li>
          <li>
            Unlimited Statistics, analytics, graphs.
          </li>
          <li>
            Unlimited measurements history, activity history and other history
            and log results.
          </li>
        </ul>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showNotificationsDialog"
      scrollable
      :fullscreen="isPhone"
      overlay-opacity="0.97"
      overlay-color="charcoal"
      @keydown.esc="cancelNotifications"
      :max-width="dialogWidth"
    >
      <v-card
        v-touch:swipe.left="cancelNotifications"
        class="charcoal d-flex flex-column noscroll-x overflow-y-auto"
        flat
        tile
      >
        <BaseCloseDialogHeader
          heading="Notifications"
          @cancel="cancelNotifications"
        />
        <BaseLoading
          class="mt-5 mb-5"
          :loaded="notificationsLoaded"
          label="Loading Notifications..."
        />
        <template v-if="notificationsLoaded">
          <template v-if="notifications.length > 0">
            <v-card-actions class="px-0 mb-5 py-0">
              <v-spacer />
              <BaseActionButton
                icon=""
                text
                small
                color="silver"
                label="Clear All Notifications"
                title="Clear Notifications"
                @clickedThis="deleteNotifications"
              />
            </v-card-actions>
            <v-card-text class="px-2">
              <v-sheet
                class="charcoal paper--text pa-2 mb-2"
                :style="charcoalTile"
                rounded
                v-for="item in notifications"
                :key="item.id"
                v-touch:swipe="(currentItem = item)"
                v-touch:swipe.left="swipeOff"
              >
                <v-card-actions class="caption pa-0 px-2 font-italic">
                  <span
                    :class="
                      item.read == 'No'
                        ? 'paper--text'
                        : 'silver--text text--lighten-1'
                    "
                  >
                    {{ prettyDateTime(item.created_local) }}
                  </span>
                  <v-spacer />
                  <v-icon
                    v-if="canEdit(item)"
                    color="silver"
                    small
                    @click="deleteNotification(item)"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </v-card-actions>
                <v-card-text class="px-2 py-0 mb-3">
                  <v-card-text class="pa-0">
                    <BaseUserProfile :item="item" textColor="success--text" />
                    <span
                      :class="
                        item.read == 'No'
                          ? 'paper--text'
                          : 'silver--text text--lighten-1'
                      "
                      v-if="item.type == 'EVENT_LIKED'"
                      @click="viewPost(item)"
                    >
                      liked one of your
                      <span class="success--text link"> feed items.</span>
                    </span>
                    <span
                      :class="
                        item.read == 'No'
                          ? 'paper--text'
                          : 'silver--text text--lighten-1'
                      "
                      v-else-if="item.type == 'EVENT_COMMENTED'"
                      @click="viewPost(item)"
                    >
                      commented on one of your
                      <span class="success--text link"> feed items.</span>
                    </span>
                    <span
                      :class="
                        item.read == 'No'
                          ? 'paper--text'
                          : 'silver--text text--lighten-1'
                      "
                      v-else-if="item.type == 'COMMENT_LIKED'"
                      @click="viewComment(item)"
                    >
                      liked one of your
                      <span class="success--text link"> comments.</span>
                    </span>
                    <template v-else-if="item.type == 'WORKOUT_SHARED'">
                      <span
                        :class="
                          item.read == 'No'
                            ? 'paper--text'
                            : 'silver--text text--lighten-1'
                        "
                      >
                        {{ item.details }}
                      </span>
                      <v-card-actions class="px-0 pb-0">
                        <v-btn
                          color="paper"
                          text
                          @click="copyWorkout(item.data)"
                          class="ml-n2"
                        >
                          <v-icon>mdi-pencil-box-multiple-outline</v-icon> Copy
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="progressActive"
                          text
                          @click="startWorkout(item.data)"
                        >
                          <v-icon>mdi-play-box-outline </v-icon>
                          Start Workout
                        </v-btn>
                      </v-card-actions>
                    </template>
                    <span
                      v-else
                      v-html="item.details"
                      :class="
                        item.read == 'No' ? 'paper--text' : 'silver--text'
                      "
                    />
                  </v-card-text>
                </v-card-text>
              </v-sheet>
            </v-card-text>
          </template>

          <template v-else>
            <v-card class="transparent silver--text noscroll-x" tile flat>
              <v-card-title class="px-2 pb-1">
                Nothing to show at this time.
              </v-card-title>
            </v-card>
          </template>
        </template>
      </v-card>
    </v-dialog>
    <v-sheet width="100%" class="updater  charcoalTileMenu">
      <template v-if="loaded && latestVersion != null">
        <v-sheet
          v-if="swWaiting || (updateExists && isIOS())"
          class="pa-1 mt-1 opacity-80 rounded text-center rounded-t-0 caption updateNotification progressActive charcoal--text link elevation-1 font-weight-bold"
          @click="updateApp('click')"
        >
          <span v-if="!loading"> Update to v {{ latestVersion.version }} </span>
          <span v-else> Updating... </span>
        </v-sheet>
        <span
          v-if="loading"
          class="charcoal--text text--lighten-3 mt-7 updateNotification"
        >
          ..
        </span>
      </template>
    </v-sheet>
  </v-app-bar>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import links from '@/json/navbarmenu.json'
import { mapActions } from 'vuex'
import util from '@/mixins/util.js'


const FullMenu = () => import('@/components/navigation/FullMenu.vue')

export default {
  mixins: [util],
  components: {
    FullMenu
  },
  computed: {
    ...appConfig,
    ...builder
  },
  beforeMount() {
    this.imageLogo = require('@/assets/Logo100.png')
    if (this.loggedIn) {
      this.loaded = false
      this.isEmailConfirmed()
      this.checkNotifications()
      this.loadSubscription()
    } else this.loaded = true
  },

  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
    window.removeEventListener('purchase_complete', this.purchaseComplete)
  },
  async mounted() {
    window.addEventListener('message', this.receiveMessage)
    window.addEventListener('purchase_complete', this.purchaseComplete)
    await this.loadVersion()
    this.manageServiceWorker()
  },
  beforeDestroy() {
    clearInterval(this.polling)
  },
  created() {
    if (this.loggedIn) {
      this.pollData()
    }
  },
  data: () => ({
    currentItem: null,
    vcode: '',
    isConfirmed: false,
    links: links,
    open: false,
    profileLoaded: false,
    notificationsLoaded: true,
    numNotifications: 0,
    showFullMenuDialog: false,
    showProWelcomeDialog: false,
    showNotificationsDialog: false,
    loaded: false,
    polling: null,
    profile: null,
    notifications: [],
    readNotifications: [],
    hasNotifications: false,
    imageLogo: null,
    latestVersion: null,
    registration: null,
    swWaiting: false,
    updateExists: false,
    loading: false
  }),
  watch: {
    confirmed(value) {
      if (value) this.isEmailConfirmed()
    },
    showNotificationsDialog: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    }
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showFullMenuDialog) this.showFullMenuDialog = false
        else if (this.showNotificationsDialog)
          this.showNotificationsDialog = false
      }
    },
    purchaseComplete(event) {
      if (
        event.detail.message == 'PURCHASE_COMPLETE' &&
        event.detail.key == 'Bflmc5aJqE'
      ) {
        this.showProWelcomeDialog = true
      }
    },
    cancelPurchaseComplete() {
      this.showProWelcomeDialog = false
      this.$router.push('/')
    },
    hamburgerClass() {
      if (this.isPhone) {
        if (this.loggedIn) return 'hamburger btn-fix ml-n1'
        else return 'hamburger-nli btn-fix'
      } else {
        return 'hamburger-desktop btn-fix'
      }
    },
    clearCaches() {
      caches.keys().then(function(cacheNames) {
        return Promise.all(
          cacheNames
            //.filter(function(cacheName) {})
            .map(function(cacheName) {
              return caches.delete(cacheName)
            })
        )
      })
    },
    async manageServiceWorker() {
      this.updateExists =
        this.versionNum() < parseInt(this.latestVersion.version_num)
      this.registration = await navigator.serviceWorker.getRegistration()
      if (this.registration) {
        if (this.registration.installing) {
          if (this.latestVersion.rebuild_cache == 'Yes') {
            this.clearCaches()
          }
          this.registration.installing.addEventListener('statechange', () => {
            if (this.registration.waiting) {
              this.swWaiting = true
              this.registration.waiting.postMessage('SKIP_WAITING')
            } else {
              null
            }
          })
        }
        if (this.registration.waiting) {
          this.swWaiting = true
          this.registration.waiting.postMessage('SKIP_WAITING')
        } else {
          null
        }
      }
    },

    updateApp(e) {
      if (e == 'bottom' || e == 'click') {
        this.loading = true
        if (this.registration && this.registration.waiting) {
          this.registration.waiting.postMessage('SKIP_WAITING')
        }
        setTimeout(() => {
          this.updateExists = false
          this.loading = false
          this.reloadBFit()
        }, 1000)
      }
    },
    loadVersion() {
      return axios.get(this.baseURL + '/version').then(response => {
        if (response.status == 200) {
          this.latestVersion = response.data.data
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    },
    copyWorkout(data) {
      return axios
        .post(this.baseURL + '/workout/copyshared', {
          workoutid: data.workoutid,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            this.toggleNotifications()
            this.toast('Workout ' + data.name + ' was copied!')
            this.goTo('/myworkouts')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    startWorkout(workout) {
      localStorage.setItem('startWorkout', true)
      let id = workout.workoutid

      let workoutType = workout.workout_type
      let path = '/workouts/start/' + id
      if (workoutType == 'Circuit') path = '/workouts/start/hiit/' + id
      else if (workoutType == 'Hybrid') {
        let xmode = workout.xmode
        let type = 'rft'
        if (xmode == 'time') type = 'rft'
        else type = xmode
        path = '/workouts/start/' + type + '/' + id
        // emom, rft, amrap
      }
      this.toggleNotifications()
      this.$router.push({
        path: path
      })
    },
    swipeOff() {
      this.deleteNotification(this.currentItem)
    },
    viewPost(item) {
      let what = null
      this.cancelNotifications()
      if (item.data.eventid) what = item.data.eventid
      else what = item.data.referenceid
      this.$router.push('/post/view/' + what)
    },
    viewComment(item) {
      this.cancelNotifications()
      this.$router.push('/comment/view/' + item.data.commentid)
    },
    cancelNotifications() {
      this.numNotifications = 0
      this.showNotificationsDialog = false
      this.hasNotifications = false
    },
    pollData() {
      this.polling = setInterval(() => {
        this.checkNotifications()
        this.manageServiceWorker()
        this.loadVersion()
        this.syncUp()
      }, this.pollingInterval)
    },
    syncUp() {
      let workouts_queued = localStorage.getItem('workouts_queued')
      if (workouts_queued != null) {
        workouts_queued = JSON.parse(workouts_queued)
        if (workouts_queued.length > 0) {
          let workout = workouts_queued[0]
          this.syncWorkout(workout)
        }
      }
    },
    toggleNotifications() {
      this.showNotificationsDialog = !this.showNotificationsDialog
      if (this.showNotificationsDialog) this.loadAllNotifications()
    },
    showProfile(profileid) {
      this.$router.push('/profile/' + profileid)
    },
    previewProfile(item) {
      this.profileLoaded = false
      return axios
        .get(this.baseURL + '/user/public/profile/' + item.data.profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.profile = response.data.data
            this.profileLoaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    verifyEmail() {
      return axios
        .get(this.baseURL + '/accounts/confirm/' + this.vcode, {})
        .then(response => {
          if (response.status == 200) {
            if (response.data.data != false) {
              this.$store.dispatch('session/setConfirmed')
              this.isConfirmed = true
              this.$store.dispatch(
                'notification/addNotification',
                'Verification code was valid, your email is now verified.',
                2000,
                true,
                {
                  root: true
                }
              )
              this.$router.push('/')
              this.switchNum++
            }
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    resendCode() {
      return axios
        .get(this.baseURL + '/users/resendcode', {})
        .then(response => {
          if (response.status == 200) {
            this.$store.dispatch(
              'notification/addNotification',
              'Verification code was re-sent, please check your email.',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    isEmailConfirmed() {
      if (this.confirmed) {
        this.isConfirmed = true
        return
      }
      return axios
        .get(this.baseURL + '/users/isconfirmed', {})
        .then(response => {
          if (response.status == 200) {
            this.isConfirmed = response.data.data['confirmed'] == 'Yes'
            if (this.isConfirmed) {
              this.$store.dispatch('session/setConfirmed')
            } else {
              this.$store.dispatch('session/unsetConfirmed')
            }
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteNotification(item) {
      return axios
        .delete(this.baseURL + '/notification/' + item.id, {})
        .then(response => {
          if (response.status == 200) {
            this.notifications = this.notifications.filter(el => {
              return el.id != item.id
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    checkNotifications() {
      if (this.loggedIn) {
        return axios
          .get(this.baseURL + '/notifications/has', {})
          .then(response => {
            if (response.status == 200) {
              this.numNotifications =
                response.data.data['num'] > 0 ? response.data.data['num'] : 0
              this.hasNotifications = this.numNotifications > 0
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      } else return
    },
    deleteNotifications() {
      return axios
        .delete(this.baseURL + '/notifications', {})
        .then(response => {
          if (response.status == 200) {
            this.loadAllNotifications()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadAllNotifications() {
      this.notificationsLoaded = false
      return axios
        .get(this.baseURL + '/notifications/all', {})
        .then(response => {
          if (response.status == 200) {
            this.notifications = response.data.data
            this.notifications.forEach(element => {
              element.preview_profile = false
              element.data = JSON.parse(element.data)
            })
            this.hasNotifications = this.notifications.length > 0
            this.notificationsLoaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    goTo(path) {
      if (this.$route.path != path) this.$router.push(path)
    },
    loadSubscription() {
      this.loaded = false
      if (this.loggedIn) {
        this.$store.dispatch('session/loadSubscription', {})
      }
      this.loaded = true
    },
    closeMenu() {
      this.showFullMenuDialog = false
      this.open = false
    },
    menuClick() {
      this.toggle()
      this.switchNum++
    },
    toggle() {
      this.showFullMenuDialog = true
      this.open = !this.open
    },
    ...mapActions('builder', [
      'exerciseAdd',
      'exerciseRemove',
      'exercisesClear'
    ])
  }
}
</script>

<style scoped>
.updater {
  z-index: 95;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  top: 45px;
}
.updateNotification {
  z-index: 95;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0px;
}
.hamburger {
  z-index: 95;
  position: absolute;
  left: 0px;
  top: 5px;
}

.hamburger-nli {
  z-index: 95;
  position: absolute;
  right: 0px;
  top: 5px;
}
.hamburger-desktop {
  z-index: 95;
  position: absolute;
  right: 10px;
  top: 15px;
}
</style>
