<template>
  <v-chip
    v-bind:x-small="is_xsmall"
    v-bind:small="is_small"
    v-bind:medium="is_medium"
    v-bind:large="is_large"
    v-bind:x-large="is_xlarge"
    class="ma-1 pa-2"
    :color="color"
    label
    :text-color="textColor"
    @click="handleClick"
  >
    <v-icon
      v-bind:x-small="is_xsmall"
      v-bind:small="is_small"
      v-bind:medium="is_medium"
      v-bind:large="is_large"
      v-bind:x-large="is_xlarge"
      left
      >{{ icon }}</v-icon
    >
    <span class="ml-0">{{ label }}</span>
  </v-chip>
</template>
<script>
export default {
  props: {
    label: {
      type: String
    },
    ripple: {
      type: Boolean,
      default: true
    },
    tagsize: {
      type: String,
      default: 'x-small'
    },
    color: {
      type: String,
      default: 'charcoal paper--text'
    },
    icon: {
      type: String,
      default: 'mdi-tag'
    },
    textColor: {
      type: String,
      default: 'charcoal--text'
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  },
  computed: {
    is_xsmall: function() {
      return this.tagsize == 'x-small'
    },
    is_small: function() {
      return this.tagsize == 'small'
    },
    is_medium: function() {
      return this.tagsize == 'medium'
    },
    is_large: function() {
      return this.tagsize == 'large'
    },
    is_xlarge: function() {
      return this.tagsize == 'x-large'
    }
  }
}
</script>
