<template>
  <v-icon :large="large" class="ml-1 paper--text mb-1">{{ icon }}</v-icon>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    icon: {
      type: String,
      default: 'mdi-open-in-new'
    },
    large: {
      type: Boolean,
      default: false
    }
  }
}
</script>
