<template>
  <v-menu
    v-model="preview"
    :close-on-content-click="false"
    :nudge-width="220"
    offset-overflow
    right
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <span
        @click="previewProfile(item)"
        :class="textColor"
        v-bind="attrs"
        v-on="on"
      >
        {{ item.display_name ? item.display_name : item.data.display_name }}
      </span>
    </template>
    <v-card
      outlined
      class="py-2 charcoal lighten-1 silver--text"
      rounded
      v-if="profileLoaded"
    >
      <v-card-title class="paper--text pa-0 pl-2 my-0">
        {{ profile.display_name }}
      </v-card-title>
      <v-card-text class="silver--text pa-0 pl-2">
        {{ profile.sex }}, age {{ profile.age }}
      </v-card-text>
      <v-card-actions class="pa-0 pl-2">
        <v-badge
          :content="!following ? 'Not Following' : 'Following'"
          color="charcoal lighten-2"
          offset-x="5"
          offset-y="20"
          right
        >
          <v-avatar
            :title="profile.display_name"
            color="progressActive"
            :size="isPhone ? 80 : 150"
            tile
          >
            <v-img
              v-if="profile.picture_cdn != null"
              :alt="profile.display_name"
              :src="resizedName(profile.picture_cdn, '150')"
              contain
              :width="isPhone ? 80 : 150"
            />
            <span
              :class="textSizeLarge + ' progressActive charcoal--text '"
              v-else
            >
              {{ initials(profile.display_name) }}
            </span>
          </v-avatar>
        </v-badge>
        <v-card class="transparent" flat> </v-card>
      </v-card-actions>
      <v-card-actions class="pa-0">
        <BaseActionButton
          icon=""
          text
          color="progressActive"
          label="Profile"
          title="View Profile"
          @clickedThis="showProfile(profile.id)"
        />
        <v-spacer />
        <BaseActionButton
          v-if="!following"
          label="Follow"
          text
          color="progressActive"
          :large="!isPhone"
          icon="mdi-account-star"
          @clickedThis="follow(item)"
        />
        <template v-else>
          <BaseActionButton
            label="UnFollow"
            class="silver--text"
            text
            :large="!isPhone"
            icon="mdi-account-remove"
            @clickedThis="unfollow(item)"
          />
        </template>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  components: {},
  name: 'ViwUserProfile',
  data: () => ({
    preview: false,
    profile: null,
    following: false,
    profileLoaded: false
  }),
  computed: {
    ...appConfig,
    ...builder
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    textColor: {
      type: String,
      default: 'progressActive--text'
    }
  },
  beforeMount() {},
  beforeDestroy() {
    this.reset()
  },
  methods: {
    reset() {
      this.profile = null
      this.following = false
      this.profileLoaded = false
    },
    showProfile(profileid) {
      this.$router.push('/profile/' + profileid)
    },
    follow(item) {
      let profileid = item.profileid ? item.profileid : item.data.profileid
      return axios
        .post(this.baseURL + '/user/follow/' + profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.isFollowing(profileid)
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    unfollow(item) {
      let profileid = item.profileid ? item.profileid : item.data.profileid
      return axios
        .post(this.baseURL + '/user/unfollow/' + profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.isFollowing(profileid)
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    isFollowing(profileid) {
      return axios
        .get(this.baseURL + '/user/isfollowing/' + profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.following = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    previewProfile(item) {
      let profileid = item.profileid ? item.profileid : item.data.profileid
      this.profileLoaded = false
      return axios
        .get(this.baseURL + '/user/public/profile/' + profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.profile = response.data.data
            this.isFollowing(profileid)
            this.profileLoaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
