<template>
  <span>
    <v-icon :large="large" :color="color" v-if="value < 10">
      {{ 'mdi-numeric-' + this.firstDigit + '-circle-outline' }}
    </v-icon>
    <template v-if="value >= 10 && value < 100">
      <v-icon :large="large" class="ml-n2" :color="color">
        {{ 'mdi-numeric-' + this.firstDigit }}
      </v-icon>
      <v-icon :large="large" :color="color" class="ml-n6 mr-n2">
        {{ 'mdi-numeric-' + this.secondDigit }}
      </v-icon>
    </template>
  </span>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: 'paper'
    },
    large: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    firstDigit: null,
    secondDigit: null,
    thirdDigit: null
  }),
  mounted() {
    if (this.value < 10) this.firstDigit = this.value
    else if (this.value >= 10 && this.value < 100) {
      this.firstDigit = Math.floor((this.value / 10) % 10)
      this.secondDigit = Math.floor((this.value / 1) % 10)
    }
  },
  methods: {}
}
</script>
