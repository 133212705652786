<template>
  <v-row class="pa-0 ma-0" dense v-touch:swipe.down="swipeDown">
    <v-sheet
      rounded
      class="screenTitle pb-0 transparent paper--text"
      style="z-index: 98"
    >
      <span v-if="reloading" :class="textSizeSmall + ' silver--text'">
        Reloading
      </span>
      <span v-else :class="textSize">
        <v-sheet class="paper--text transparent text-center ">
          <template v-if="showBack && showNav">
            <v-icon
              title="Go Back"
              color="silver"
              class="ml-n5 mr-n1 mt-n1"
              @click="$router.go(-1)"
            >
              mdi-arrow-left
            </v-icon>
          </template>
          <v-icon
            v-if="refresh && !showBack && showNav"
            title="Refresh"
            color="paper"
            class="opacity-30 ml-n5 mr-n1 mt-n1"
          >
            mdi-refresh-circle
          </v-icon>
          {{ title }}
        </v-sheet>
      </span>
    </v-sheet>
  </v-row>
</template>
<script>
import util from '@/mixins/util.js'
import { appConfig } from '@/store/helpers.js'

export default {
  components: {},
  mixins: [util],
  data() {
    return {
      show: false,
      reloading: false,
      previousRoute: null
    }
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    screenInfo: {
      type: String,
      required: true,
      default: ''
    },
    showBack: {
      type: Boolean,
      default: true
    },
    showNav: {
      type: Boolean,
      default: false
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...appConfig
  },
  methods: {
    swipeDown(loaded) {
      this.reloading = true
      setTimeout(() => {
        this.reloading = false
        if (loaded) this.refreshOrReload()
      }, 500)
    },
    refreshOrReload() {
      if (this.refresh) {
        this.$emit('refresh')
      } else this.$router.go()
    },
    goBackOrReload() {
      if (this.refresh) {
        this.$emit('refresh')
      } else this.$router.go(-1)
    }
  }
}
</script>
<style scoped>
.refresh {
  position: fixed;
  left: 2px;
  top: 5px;
  z-index: 98;
  border-radius: 0px 20px 20px 0px;
}
.screenTitle {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 30px;
  z-index: 98;
}
</style>
