import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import App from './App.vue'
import router from './router'
import store from './store/store'
import Vuelidate from 'vuelidate'
import DatetimePicker from 'vuetify-datetime-picker'
import vuetify from '@/plugins/vuetify'
import '@/plugins/tiptap-vuetify'
import axios from 'axios'
import VTooltip from 'v-tooltip'
import Vue2TouchEvents from 'vue2-touch-events'
import VueCookies from 'vue-cookies'
import VideoBackground from 'vue-responsive-video-background-player'
import Gleap from 'gleap'
Gleap.initialize('S3GmzLQE3ZQPeulpZJIIm7uJiXA44Obg')
import VueTypedJs from 'vue-typed-js'

const production = false

Vue.config.devtools = production

Vue.use(Vue2TouchEvents)
Vue.use(VTooltip)
Vue.use(Vuelidate)
Vue.use(DatetimePicker)
Vue.use(VueTypedJs)
Vue.component('video-background', VideoBackground)

Vue.use(VueCookies)
Vue.config.productionTip = production
Vue.config.devtools = !production
Vue.config.silent = production

const requireComponent = require.context(
  './components',
  true,
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.config.productionTip = production
new Vue({
  vuetify,
  router,
  store,
  created() {
    const user = Vue.$cookies.get('user')
    if (user) {
      this.$store.dispatch('session/autoLogin', user)
    }
    axios.interceptors.response.use(
      response => {
        if (response.status === 200 || response.status === 202) {
          return Promise.resolve(response)
        } else if (response.status === 401) {
          this.$store.dispatch('session/logout')
          return Promise.reject(response)
        } else {
          return Promise.reject(response)
        }
      },
      error => {
        if (error.response) {
          if (error.response.status === 401) {
            this.$store.dispatch('session/logout')
          }
        }

        return Promise.reject(error)
      }
    )
  },
  render: h => h(App)
}).$mount('#app')
