<template>
  <v-btn
    :x-large="xlarge"
    :large="large && !small"
    :small="small"
    :outlined="outlined"
    :text="text"
    :plain="plain"
    :color="color"
    :disabled="disabled"
    :to="to"
    :href="href"
    :block="block"
    @click="$emit('clickedThis')"
  >
    <v-icon
      v-if="icon != ''"
      :x-large="xlarge"
      :small="small"
      :large="(large || nolabel) && !small && !xlarge"
      :class="nolabel ? '' : 'mr-1'"
    >
      {{ icon }}
    </v-icon>
    {{ nolabel ? '' : label }}
  </v-btn>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Go'
    },
    color: {
      type: String,
      default: 'accent'
    },
    to: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'mdi-link-variant'
    },
    large: {
      type: Boolean,
      default: true
    },
    xlarge: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    nolabel: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: true
    },
    plain: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    outlined() {
      return !(this.text || this.plain)
    }
  }
}
</script>
