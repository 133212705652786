import { dynamicSortMultiple, defaultRepScheme } from '@/mixins/util.js'

export const namespaced = true

export const state = {
  exerciseList: []
}

export const mutations = {
  ADD_EXERCISE(state, exercise) {
    let isWOD =
      exercise.workoutType == 'WOD' || exercise.workoutType == 'Hybrid'
    let noSets =
      exercise.track_distance == 'Yes' || exercise.track_time == 'Yes' || isWOD

    if (
      exercise.repschemeid == '' ||
      !exercise.repschemeid ||
      typeof exercise.repschemeid === 'undefined'
    ) {
      exercise.repschemeid = defaultRepScheme()
    }
    if (!exercise.distance_metric) {
      exercise.distance_imperial = 0
      exercise.distance_metric = 0
    }
    if (!exercise.time) exercise.time = 0

    if (noSets) {
      exercise.sets = 1
      exercise.reps = 1
    } else {
      if (!exercise.sets > 0) exercise.sets = 5
      if (!exercise.reps > 0) exercise.reps = 10
    }

    if (exercise.maxtest == 'Yes' && !isWOD) {
      if (!exercise.pmax > 0) exercise.pmax = 70
    } else exercise.pmax = 0

    let ex = {
      ...exercise
    }
    ex.sort = state.exerciseList.length + 1
    state.exerciseList.push(ex)
  },
  CLEAR_ALL(state) {
    state.exerciseList = []
  },
  REMOVE_EXERCISE(state, exercise) {
    state.exerciseList = state.exerciseList.filter(item => {
      return item !== exercise
    })

    let i = 1
    state.exerciseList.forEach(element => {
      element.sort = i
      ++i
    })
  },
  REMOVE_LAST_EXERCISE(state, exercise) {
    var last = state.exerciseList
      .slice()
      .reverse()
      .find(last => last.id === exercise.id)
    state.exerciseList = state.exerciseList.filter(item => {
      return item !== last
    })
  },
  SORT_EXERCISES(state) {
    state.exerciseList.sort(dynamicSortMultiple('sort'))
  }
}

export const actions = {
  exerciseAdd({ commit }, exercise) {
    commit('ADD_EXERCISE', exercise)
  },
  exercisesClear({ commit }) {
    commit('CLEAR_ALL')
  },
  exerciseRemove({ commit }, exercise) {
    commit('REMOVE_EXERCISE', exercise)
  },
  exerciseRemoveLast({ commit }, exercise) {
    commit('REMOVE_LAST_EXERCISE', exercise)
  },
  exercisesSort({ commit }) {
    commit('SORT_EXERCISES')
  }
}

export const getters = {
  exercisesList(state) {
    return state.exerciseList
  },
  exercisesListSize(state) {
    if (state.exerciseList == []) return 0
    return state.exerciseList.length
  }
}

export default {
  state,
  actions,
  getters
}
