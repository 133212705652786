<template>
  <a v-if="large" target="_blank" href="https://www.edamam.com/">
    <v-img
      :src="require('@/assets/Edamam_Badge.svg')"
      contain
      position="center center"
      title="Powered By Edamam"
    />
  </a>
  <v-img
    class="ml-n2"
    v-else
    max-width="160"
    :src="require('@/assets/Edamam_Badge.svg')"
    title="Powered By Edamam"
  />
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    large: {
      type: Boolean,
      default: false
    }
  }
}
</script>
