<template>
  <v-btn
    right
    :width="width"
    :title="title"
    :text="text"
    :large="large"
    :plain="plain"
    :outlined="!text && !plain"
    :color="color"
    :to="url"
    :disabled="disabled"
    @click="$emit('clickedThis')"
  >
    <v-icon class="mr-2">
      {{ icon }}
    </v-icon>
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  props: {
    url: {
      type: String
    },
    label: {
      type: String,
      default: 'Add'
    },
    title: {
      type: String,
      default: 'Add More'
    },
    icon: {
      type: String,
      default: 'mdi-plus-circle-outline'
    },
    width: {
      type: Number,
      default: 120
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'accent'
    }
  }
}
</script>
