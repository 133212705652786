export const namespaced = true
export const state = {
  notifications: []
}

let nextId = 1
export const mutations = {
  PUSH(state, notification) {
    state.notifications = []
    state.notifications.push({
      ...notification,
      id: nextId++
    })
  },
  CLEAR(state) {
    state.notifications = []
  },
  PUSH_ERRORS(state, errors, expires) {
    state.notifications = []

    const objectArray = Object.entries(errors)
    objectArray.forEach(([key, value]) => {
      const item = {
        counter: key,
        type: value['type'],
        message: value['message'],
        timeout: expires
      }
      state.notifications.push({
        ...item,
        id: nextId++
      })
    })
  },

  PUSH_NOTIFICATION(state, notification) {
    if (notification.reset) state.notifications = []
    const item = {
      counter: 1,
      type: notification.type,
      message: notification.text,
      timeout: notification.expires
    }

    state.notifications.push({
      ...item,
      id: nextId++
    })
  },

  ADD_NOTIFICATION(state, text, expires, reset) {
    if (reset) state.notifications = []
    const item = {
      counter: 1,
      type: 'success',
      message: text,
      timeout: expires
    }

    state.notifications.push({
      ...item,
      id: nextId++
    })
  },
  DELETE(state, notificationToRemove) {
    state.notifications = state.notifications.filter(
      notification => notification.id !== notificationToRemove.id
    )
  }
}

export const actions = {
  add({ commit }, notification) {
    commit('PUSH', notification)
  },
  remove({ commit }, notificationToRemove) {
    commit('DELETE', notificationToRemove)
  },
  addErrors({ commit }, errors, expires, reset) {
    commit('PUSH_ERRORS', errors, expires, reset)
  },
  addNotification({ commit }, text, expires, reset) {
    commit('ADD_NOTIFICATION', text, expires, reset)
  },
  pushNotification({ commit }, notification) {
    commit('PUSH_NOTIFICATION', notification)
  },

  clear({ commit }) {
    commit('CLEAR')
  }
}
