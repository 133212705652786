<template>
  <v-app id="app">
    <NotificationContainer class="ontop" />
    <NavBar />
    <v-main class="charcoal">
      <v-container class="pa-0">
        <router-view v-slot="{ Component }" class="py-0 mt-n2">
          <transition name="slide" mode="out-in">
            <v-card class="transparent" tile flat>
              <component :is="Component" />
            </v-card>
          </transition>
        </router-view>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
const NotificationContainer = () =>
  import(/* webpackPrefetch: true */ '@/components/NotificationContainer.vue')
import NavBar from '@/components/navigation/NavBar.vue'
import Footer from '@/components/navigation/Footer.vue'
import { appConfig } from '@/store/helpers.js'
export default {
  name: 'BFit',
  data: () => ({}),
  components: {
    NotificationContainer,
    NavBar,
    Footer
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('autologin', this.appLogin)
      window.addEventListener('app_navigate', this.appNavigate)
    })
  },
  beforeDestroy() {
    window.removeEventListener('autologin', this.appLogin)
    window.removeEventListener('app_navigate', this.appNavigate)
  },

  computed: {
    ...appConfig
  },
  methods: {
    appNavigate(event) {
      if (this.isModalOpen) {
        let msg = new Object()
        msg.type = 'CLOSE_MODAL'
        msg.key = 'UoMc92xqvA'
        window.postMessage(msg, "https://bfit.fit")
        return
      }
      if (
        event.detail.message == 'GO_BACK' &&
        event.detail.key == 'LULqJm04Gav'
      ) {
        localStorage.setItem('isbackNavigation', true)
        this.$router.go(-1)
      }
      if (
        event.detail.message == 'GO_FORWARD' &&
        event.detail.key == 'LULqJm04Gav'
      ) {
        this.$router.go(1)
      }
    },

    appLogin(event) {
      const user = this.$cookies.get('user')
      if (
        (!this.loggedIn || !user) &&
        event.detail.message == 'LOGIN-WAS-FOUND' &&
        event.detail.key == 'dVKrJrPAT8'
      ) {
        this.autouser = JSON.parse(event.detail.data)
        this.$store.dispatch('session/nativeAutoLogin', this.autouser)
      }
      if (this.loggedIn) {
        this.$router.push('/')
        this.$store.dispatch('session/loadSubscription', {})
      }
    }
  }
}
</script>
<style>
.ontop {
  position: fixed;
  z-index: 99 !important;
  width: 100%;
}

@import 'assets/global.css';
</style>
