<template>
  <v-card-title
    class="charcoal  paper--text  noscroll-x pa-2 pt-1 mb-1"
  >
    <v-icon :color="color" v-if="icon != null">
      {{ icon }}
    </v-icon>
    <span :class="color + '--text ml-1'">{{ heading }}</span>
    <v-spacer />
    <template v-if="closable">
      <v-icon
        size="45"
        color="silver"
        @click="$emit('cancel')"
        title="Close"
        class="mt-n1 mr-n2 top-click"
      >
        mdi-close-box
      </v-icon>
    </template>
  </v-card-title>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      required: false,
      default: null
    },
    color: {
      type: String,
      required: false,
      default: 'paper'
    },
    heading: {
      type: String,
      required: true,
      default: ''
    },
    closable: {
      Boolean,
      default: true
    }
  }
}
</script>
<style>
.top-click {
  z-index: 100; /* adjust the value as needed */
}
</style>
