import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'

const NotFound = () =>
  import(/* webpackPrefetch: true */ '@/views/NotFound.vue')
const NetworkIssue = () =>
  import(/* webpackPrefetch: true */ '@/views/NetworkIssue.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/feed',
    name: 'feed',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Tools" */ '@/components/pages/Feed.vue'
      )
  },
  {
    path: '/energy',
    name: 'energy',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Nutrition" */ '@/views/nutrition/EnergyLog.vue'
      )
  },
  {
    path: '/mypantry',
    name: 'mypantry',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Nutrition" */ '@/views/nutrition/MyPantry.vue'
      )
  },
  {
    path: '/foodlog/:day',
    name: 'foodlogdetails',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Nutrition" */ '@/views/nutrition/FoodLogDetails.vue'
      )
  },
  {
    path: '/foodlog',
    name: 'foodlog',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Nutrition" */ '@/views/nutrition/FoodLog.vue'
      )
  },
  {
    path: '/supplementslog',
    name: 'supplementslog',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Nutrition" */ '@/views/nutrition/SupplementsLog.vue'
      )
  },
  {
    path: '/post/view/:itemid',
    name: 'viewpost',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Posts" */ '@/views/posts/ViewPost.vue'
      )
  },
  {
    path: '/comment/view/:itemid',
    name: 'viewcomment',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Comments" */ '@/views/posts/ViewComment.vue'
      )
  },
  {
    path: '/admin/users',
    name: 'adminusers',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Tools" */ '@/views/admin/UserList.vue'
      )
  },
  {
    path: '/admin/news',
    name: 'adminnews',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Tools" */ '@/views/admin/News.vue'
      )
  },
  {
    path: '/articles/add',
    name: 'addarticle',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Articles" */ '@/views/articles/AddArticle.vue'
      )
  },
  {
    path: '/articles/view/:articleid',
    props: true,
    name: 'viewarticle',
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Articles" */ '@/views/articles/View.vue'
      )
  },
  {
    path: '/articles',
    name: 'listarticles',
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Articles" */ '@/views/articles/List.vue'
      )
  },
  {
    path: '/search/bymuscle/:muscleid',
    name: 'searchbymuscle',
    props: true,
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Search" */ '@/views/search/ByMuscle.vue'
      )
  },
  {
    path: '/confirm/:token',
    name: 'confirmtoken',
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/account/ConfirmToken.vue'
      )
  },
  {
    path: '/',
    name: 'home',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Home" */ '@/views/Home.vue'
      ),
    props: true
  },
  {
    path: '/firsttime',
    name: 'firsttime',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Home" */ '@/views/FirstTime.vue'
      ),
    props: true
  },
  {
    path: '/openbeta',
    name: 'openbeta',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Home" */ '@/views/OpenBeta.vue'
      ),
    props: true
  },
  {
    path: '/contact',
    name: 'contact',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/Contact.vue'
      ),
    props: true
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/Unsubscribe.vue'
      ),
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/account/LoginOTP.vue'
      )
  },
  {
    path: '/otplogin',
    name: 'otplogin',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/account/LoginOTP.vue'
      )
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/account/ForgotPassword.vue'
      )
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/account/Register.vue'
      )
  },
  {
    path: '/termsandconditions',
    name: 'termsandconditions',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Policies" */ '@/views/policies/Terms.vue'
      )
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Policies" */ '@/views/About.vue'
      )
  },
  {
    path: '/acceptableuse',
    name: 'acceptableuse',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Policies" */ '@/views/policies/AcceptableUse.vue'
      )
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Policies" */ '@/views/policies/Cookies.vue'
      )
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Policies" */ '@/views/policies/Privacy.vue'
      )
  },
  {
    path: '/deleteaccount',
    name: 'deleteaccount',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "DeleteAccountInstructions" */ '@/views/DeleteAccountInstructions.vue'
      )
  },
  {
    path: '/registrationcompleted',
    name: 'registrationcompleted',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/account/RegisterComplete.vue'
      )
  },
  {
    path: '/clocks',
    name: 'clocks',
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Tools" */ '@/views/clocks/All.vue'
      )
  },

  {
    path: '/wellness',
    name: 'wellness',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Tools" */ '@/views/wellness/All.vue'
      )
  },
  {
    path: '/repschemes',
    name: 'repschemes',
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Tools" */ '@/views/tools/ListRepSchemes.vue'
      )
  },
  {
    path: '/repschemes/new',
    name: 'repschemesnew',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Tools" */ '@/views/tools/AddRepScheme.vue'
      )
  },
  {
    path: '/repschemes/:id',
    name: 'repschemesedit',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Tools" */ '@/views/tools/EditRepScheme.vue'
      )
  },
  {
    path: '/settings',
    name: 'settings',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Tools" */ '@/views/Settings.vue'
      )
  },
  {
    path: '/calculators/all',
    name: 'calculatorsall',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Calculators" */ '@/views/calculators/All.vue'
      )
  },

  {
    path: '/calculators/tdee',
    name: 'calculatortdee',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Calculators" */ '@/views/calculators/BMR.vue'
      )
  },
  {
    path: '/calculators/bmr',
    name: 'calculatorbmr',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Calculators" */ '@/views/calculators/BMR.vue'
      )
  },
  {
    path: '/calculators/protein',
    name: 'calculatorprotein',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Calculators" */ '@/views/calculators/Protein.vue'
      )
  },
  {
    path: '/calculators/bmi',
    name: 'calculatorbmi',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Calculators" */ '@/views/calculators/BMI.vue'
      )
  },
  {
    path: '/calculators/bfp',
    name: 'calculatorbfp',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Calculators" */ '@/views/calculators/BFP.vue'
      )
  },
  {
    path: '/calculators/creatinedosage',
    name: 'calculatorcreatinedosage',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Calculators" */ '@/views/calculators/Creatine.vue'
      )
  },
  {
    path: '/calculators/weightloss',
    name: 'calculatorweightloss',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Calculators" */ '@/views/calculators/WeightLoss.vue'
      )
  },
  {
    path: '/calculators/idealbodycomposition',
    name: 'calculatoridealbodycomposition',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Calculators" */ '@/views/calculators/IdealBodyComp.vue'
      )
  },
  {
    path: '/calculators/1rm',
    name: 'calculator1rm',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Calculators" */ '@/views/calculators/OneRepMax.vue'
      )
  },
  {
    path: '/calculators/reverse1rm',
    name: 'calculatorreverse1rm',
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Calculators" */ '@/views/calculators/ReverseOneRepMax.vue'
      )
  },
  {
    path: '/myaccount',
    name: 'myaccount',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "MyAccount" */ '@/views/account/My.vue'
      )
  },
  {
    path: '/invoices',
    name: 'invoices',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "MyAccount" */ '@/views/account/Invoices.vue'
      )
  },
  {
    path: '/billing',
    name: 'billing',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "MyAccount" */ '@/views/account/Billing.vue'
      )
  },
  {
    path: '/workouts/calendar',
    name: 'workouts/calendar',
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/calendars/Workouts.vue'
      )
  },
  {
    path: '/searchworkouts',
    name: 'searchworkouts',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/workouts/SearchWorkouts.vue'
      )
  },
  {
    path: '/myworkouts',
    name: 'myworkouts',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/workouts/ListWorkouts.vue'
      )
  },
  {
    path: '/workouts/database',
    name: 'workoutdsdatabase',
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/workouts/Database.vue'
      )
  },
  {
    path: '/tools',
    name: 'tools',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Tools" */ '@/views/tools/All.vue'
      )
  },
  {
    path: '/workouts/add',
    name: 'workoutsadd',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/workouts/AddWorkout.vue'
      )
  },
  {
    path: '/workouts/addwod',
    name: 'workoutsaddwod',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/workouts/AddWODWorkout.vue'
      )
  },
  {
    path: '/workouts/edit/:workoutid',
    name: 'workoutsedit',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/workouts/EditWorkout.vue'
      )
  },
  {
    path: '/workouts/manage/variations/:workoutid',
    name: 'manageworkoutvariations',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Exercise" */ '@/views/workouts/ManageVariations.vue'
      )
  },
  {
    path: '/workouts/viewcompleted',
    name: 'workoutsviewcompleted',
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/workouts/ViewCompletedWorkouts.vue'
      )
  },
  {
    path: '/workouts/packed/start/:workoutid',
    name: 'workoutsstartpacked',
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivitiest" */ '@/views/workouts/StartPackedWorkout.vue'
      )
  },
  {
    path: '/workouts/start/hiit/:workoutid',
    name: 'workoutsstarthiit',
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/workouts/StartWorkoutHiit.vue'
      )
  },
  {
    path: '/workouts/start/amrap/:workoutid',
    name: 'workoutsstartamrap',
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/workouts/StartWorkoutAmrap.vue'
      )
  },
  {
    path: '/workouts/start/emom/:workoutid',
    name: 'workoutsstartemom',
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/workouts/StartWorkoutEmom.vue'
      )
  },
  {
    path: '/workouts/start/rft/:workoutid',
    name: 'workoutsstartrft',
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/workouts/StartWorkoutRFT.vue'
      )
  },
  {
    path: '/workouts/start/:workoutid',
    name: 'workoutsstart',
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivitiest" */ '@/views/workouts/StartWorkout.vue'
      )
  },

  {
    path: '/workouts/view/:workoutinstanceid',
    name: 'workoutsview',
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/workouts/ViewWorkout.vue'
      )
  },
  {
    path: '/activities',
    name: 'activities',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "WorkoutsAndActivities" */ '@/views/activities/ActivityLog.vue'
      )
  },

  {
    path: '/fitnessglossary',
    name: 'fitnessglossary',
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/glossary/List.vue'
      )
  },
  {
    path: '/musclepedia',
    name: 'musclepedia',
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/musclepedia/List.vue'
      )
  },
  {
    path: '/muscledetails/:code',
    name: 'muscledetails',
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/musclepedia/Details.vue'
      )
  },
  {
    path: '/exercise/database',
    name: 'exercisesdatabase',
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/exercise/Database.vue'
      )
  },
  {
    path: '/exercise/xml',
    name: 'exercisesxml',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/exercise/xml.vue'
      )
  },
  {
    path: '/searchexercises',
    name: 'searchexercises',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Exercise" */ '@/views/exercise/SearchExercises.vue'
      )
  },
  {
    path: '/exercises/noequipment/:letter',
    name: 'exercisesnoequipment',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Exercise" */ '@/views/exercise/NoEquipmentExercises.vue'
      )
  },
  {
    path: '/exercises/incomplete',
    name: 'exercisesincomplete',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Exercise" */ '@/views/exercise/IncompleteExercises.vue'
      )
  },
  {
    path: '/exercise/autoadd/:exercise_name',
    name: 'exerciseautoadd',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Exercise" */ '@/views/exercise/AutoAddExercise.vue'
      )
  },
  {
    path: '/exercises/view/:exerciseid',
    name: 'viewexercise',
    props: true,
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Exercise" */ '@/views/exercise/ViewExercise.vue'
      )
  },

  {
    path: '/exercises',
    name: 'exercises',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Exercise" */ '@/views/exercise/ListExercises.vue'
      )
  },
  {
    path: '/exercises/manage/variations/:exerciseid',
    name: 'manageexercisevariations',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Exercise" */ '@/views/exercise/ManageVariations.vue'
      )
  },
  {
    path: '/exercises/add',
    name: 'addexercise',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Exercise" */ '@/views/exercise/AddExercise.vue'
      )
  },
  {
    path: '/exercises/:aiMode',
    name: 'exercisesAIMode',
    props: true,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      to.params.aiMode = to.params.aiMode === 'true'
      next()
    },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Exercise" */ '@/views/exercise/ListExercises.vue'
      )
  },

  {
    path: '/exercises/edit/:exerciseid',
    name: 'editexercise',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Exercise" */ '@/views/exercise/EditExercise.vue'
      )
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/leaderboard/List.vue'
      )
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Profile" */ '@/views/profile/My.vue'
      )
  },
  {
    path: '/profile/upload',
    name: 'upload',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Profile" */ '@/views/profile/UploadPhoto.vue'
      )
  },
  {
    path: '/profile/photo',
    name: 'profilephoto',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Profile" */ '@/views/profile/AddPhoto.vue'
      )
  },
  {
    path: '/profile/:profileid',
    name: 'publicprofile',
    props: true,
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/profile/Public.vue'
      )
  },
  {
    path: '/measurements',
    name: 'measurementslist',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ViewMeasurements" */ '@/views/measurements/List.vue'
      )
  },
  {
    path: '/stats/powerlifting',
    name: 'powerliftingstats',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Stats" */ '@/views/stats/Powerlifting.vue'
      )
  },
  {
    path: '/mystats',
    name: 'mystats',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Stats" */ '@/views/stats/My.vue'
      )
  },
  {
    path: '/mystats/bodycomposition',
    name: 'bodycomposition',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Stats" */ '@/views/stats/BodyComposition.vue'
      )
  },
  {
    path: '/mystats/weightlifting',
    name: 'weightlifting',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Stats" */ '@/views/stats/WeightLifting.vue'
      )
  },
  {
    path: '/mystrength',
    name: 'mystrength',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "MyStrength" */ '@/views/strength/My.vue'
      )
  },
  {
    path: '/standards',
    name: 'standards',
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "MyStrength" */ '@/views/strength/Standards.vue'
      )
  },
  {
    path: '/strengthdetail/:exerciseid',
    name: 'mystrengthdetail',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "MyStrength" */ '@/views/strength/Detail.vue'
      )
  },
  {
    path: '/repmax/log/:exerciseid',
    name: 'repmaxlog',
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "MyStrength" */ '@/views/strength/RepmaxLog.vue'
      )
  },
  {
    path: '/myprograms',
    name: 'myprograms',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Programs" */ '@/views/programs/ListPrograms.vue'
      )
  },
  {
    path: '/programs/edit/:programid',
    name: 'programsedit',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Programs" */ '@/views/programs/EditProgram.vue'
      )
  },
  {
    path: '/program/view/:programid',
    name: 'programview',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Programs" */ '@/views/programs/ViewProgram.vue'
      )
  },
  {
    path: '/programs/add',
    name: 'programsaddt',
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Programs" */ '@/views/programs/AddProgram.vue'
      )
  },
  {
    path: '/invites/accept/:token',
    name: 'AcceptInvite',
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Account" */ '@/views/account/AcceptInvite.vue'
      )
  },
  {
    path: '/getapp/:referer/:id',
    name: 'getapp',
    props: true,
    meta: { requiresAuth: false },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Public" */ '@/views/GetApp.vue'
      )
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Account" */ '@/views/account/Upgrade.vue'
      )
  },

  {
    path: '/upgradeapp',
    name: 'upgradeapp',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Account" */ '@/views/account/UpgradeApp.vue'
      )
  },
  {
    path: '/business/products',
    name: 'mybusinessproducts',
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "Business" */ '@/views/business/ListProducts.vue'
      )
  },
  { path: '/404', name: '404', component: NotFound, props: true },
  {
    path: '/network-issue',
    name: 'network-issue',
    component: NetworkIssue
  },
  {
    path: '*',
    redirect: { name: '404', params: { resource: 'page' } }
  }
]
const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((routeTo, routeFrom, next) => {
  store.dispatch('notification/clear')
  const isLoggedIn = Vue.$cookies.get('user') != null
  if (routeTo.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    next('/')
  }
  next()
})

router.afterEach(to => {
  store.dispatch('session/resetModals')
  if (window.ReactNativeWebView != undefined) {
    let msg = new Object()
    msg.type = 'NAVIGATION'
    msg.url = to.path
    window.ReactNativeWebView.postMessage(JSON.stringify(msg))
  }
})
export default router
