<template>
  <v-row
    v-if="!loaded"
    class="transparent fill-height silver--text"
    align-content="center"
    justify="center"
    dense
  >
    <v-col cols="6" class="transparent">
      <v-progress-linear
        color="cyan"
        indeterminate
        rounded
        height="1"
      />
    </v-col>
    <v-col class="transparent text-subtitle-1 text-center" cols="12">
      {{ label }}
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {}
  },

  props: {
    loaded: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Loading More'
    }
  }
}
</script>
