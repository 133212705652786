<template>
  <v-footer class="px-0 pt-0 pb-4 charcoal charcoalTileMenu " app>
    <v-spacer />
    <v-card tile color="transparent" flat>
      <v-divider class="silver mb-2" />
      <v-card-actions v-if="loggedIn" class="py-0">
        <v-spacer />
        <span>
          <v-row dense @click="goTo('/')">
            <v-col
              cols="12"
              class="ma-0 pa-0 d-flex justify-center align-center"
            >
              <v-btn
                fab
                elevation="0"
                class="btn-fix px-0 transparent"
                :retain-focus-on-click="false"
              >
                <v-icon color="paper">mdi-home-variant-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="ma-0 pa-0 my-n1 d-flex justify-center align-center"
            >
              <span class="caption paper--text ">HOME</span>
            </v-col>
          </v-row>
        </span>
        <span>
          <v-row dense @click="startWorkout">
            <v-col
              cols="12"
              class="ma-0 pa-0 d-flex justify-center align-center"
            >
              <v-btn
                fab
                elevation="0"
                class="btn-fix px-0 transparent"
                :retain-focus-on-click="false"
              >
                <v-icon color="paper">mdi-weight-lifter</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="ma-0 pa-0 my-n1 d-flex justify-center align-center"
            >
              <span class="caption paper--text">WORKOUT</span>
            </v-col>
          </v-row>
        </span>
        <span>
          <v-row dense @click="showAddMeasurement = true">
            <v-col
              cols="12"
              class="ma-0 pa-0 d-flex justify-center align-center"
            >
              <v-btn
                fab
                elevation="0"
                class="btn-fix px-0 transparent"
                :retain-focus-on-click="false"
              >
                <v-icon color="paper">mdi-tape-measure</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="ma-0 pa-0 my-n1 d-flex justify-center align-center"
            >
              <span class="caption paper--text">MEASURE</span>
            </v-col>
          </v-row>
        </span>
        <span>
          <v-row dense @click="showEnergy()">
            <v-col
              cols="12"
              class="ma-0 pa-0 d-flex justify-center align-center"
            >
              <v-btn
                fab
                elevation="0"
                class="btn-fix px-0 transparent"
                :retain-focus-on-click="false"
              >
                <v-icon color="paper">
                  mdi-battery-sync-outline
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="ma-0 pa-0 my-n1 d-flex justify-center align-center"
            >
              <span class="caption paper--text">ENERGY</span>
            </v-col>
          </v-row>
        </span>
        <v-spacer />
      </v-card-actions>
      <v-card-actions class="py-0" v-if="showFooterMenu || !loggedIn">
        <v-spacer />
        <span>
          <v-row dense @click="goTo('/')">
            <v-col
              cols="12"
              class="ma-0 pa-0 d-flex justify-center align-center"
            >
              <v-btn fab elevation="0" class="btn-fix px-0 transparent">
                <v-icon color="silver">mdi-home-variant-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="ma-0 pa-0 my-n1 d-flex justify-center align-center"
            >
              <span class="caption silver--text ">HOME</span>
            </v-col>
          </v-row>
        </span>
        <template v-for="link in links">
          <template
            v-if="
              (loggedIn || link.public) &&
                ((link.desktop_only && !isPhone) || !link.desktop_only) &&
                !(link.hide && loggedIn)
            "
          >
            <template v-if="link.external == false">
              <span :key="`${link.label}-footer-link`">
                <v-row dense>
                  <v-col
                    cols="12"
                    class="ma-0 pa-0 d-flex justify-center align-center"
                  >
                    <v-btn
                      v-if="link.section == 'main'"
                      fab
                      href
                      elevation="0"
                      class="btn-fix px-0 transparent"
                      :to="loggedIn ? link.alt_url : link.url"
                    >
                      <v-icon :color="link.color">
                        {{ link.icon }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    class="ma-0 pa-0 my-n1 d-flex justify-center align-center"
                  >
                    <span class="caption silver--text text-uppercase">
                      {{ link.label }}
                    </span>
                  </v-col>
                </v-row>
              </span>
            </template>
          </template>
        </template>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <v-card-actions class="py-0" v-if="!isPhone">
      <span class="text-center">
        <span class="caption copyright charcoal--text text--lighten-4 pl-4">
          &copy;{{ new Date().getFullYear() }} BFitr
        </span>
      </span>
    </v-card-actions>
    <v-spacer />
    <Activities
      v-if="loggedIn"
      :show="showActivitiesDialog"
      @saved="showActivitiesDialog = false"
      @cancel="showActivitiesDialog = false"
    />
    <Workout
      v-if="loggedIn"
      :show="showWorkoutDialog"
      :switchNum="switchNum"
      @saved="showWorkoutDialog = false"
      @cancel="showWorkoutDialog = false"
    />
    <Energy
      v-if="loggedIn"
      :show="showEnergyDialog"
      @done_with_nrg="showEnergyDialog = false"
      @cancel="showEnergyDialog = false"
    />
    <AddMeasurement
      v-if="loggedIn"
      :show="showAddMeasurement"
      @done="doneAdding"
      @cancel="showAddMeasurement = false"
    />
  </v-footer>
</template>
<script>
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import axios from 'axios'
import links from '@/json/footermenu.json'
import Gleap from 'gleap'

const Activities = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Activities.vue')
const Workout = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Workout.vue')
const Energy = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Energy.vue')

const AddMeasurement = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/AddMeasurement.vue')
export default {
  components: {
    Activities,
    Workout,
    Energy,
    AddMeasurement
  },
  mixins: [util],
  data: () => ({
    switchNum: 0,
    showFooterMenu: false,
    links: links,
    showEnergyDialog: false,
    showActivitiesDialog: false,
    showWorkoutDialog: false,
    showAddMeasurement: false,
    loaded: false
  }),
  beforeMount() {
    // Add the window event listener
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    // Clean up the window event listener
    window.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    receiveMessage(event) {
      // Check the message content and show the dialog if it matches
      if (event.data === 'show_add_measurement') {
        this.showAddMeasurement = true
      } else if (event.data === 'show_add_food') {
        this.showEnergyDialog = true
      } else if (event.data === 'show_add_activity') {
        this.showActivitiesDialog = true
      }
    },
    showEnergy() {
      this.showEnergyDialog = false
      this.showEnergyDialog = true
    },
    doneAdding() {
      this.showAddMeasurement = false
    },
    goTo(path) {
      if (this.$route.path != path) {
        this.$router.push(path)
      }
    },
    goHome() {
      if (this.$route.path != '/') this.$router.push('/')
    },
    startWorkout() {
      this.switchNum++
      this.showWorkoutDialog = true
    },
    resendCode() {
      return axios
        .get(this.baseURL + '/users/resendcode', {})
        .then(response => {
          if (response.status == 200) {
            this.$store.dispatch(
              'notification/addNotification',
              'Verification code was re-sent, please check your email.',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    verifyEmail() {
      return axios
        .get(this.baseURL + '/accounts/confirm/' + this.vcode, {})
        .then(response => {
          if (response.status == 200) {
            if (response.data.data != false) {
              this.$store.dispatch('session/setConfirmed')
              this.isConfirmed = true
              this.$store.dispatch(
                'notification/addNotification',
                'Verification code was valid, your email is now verified.',
                2000,
                true,
                {
                  root: true
                }
              )
              this.$router.push('/mystats')
              this.switchNum++
            }
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    isEmailConfirmed() {
      if (this.confirmed) {
        this.isConfirmed = true
        return
      }

      return axios
        .get(this.baseURL + '/users/isconfirmed', {})
        .then(response => {
          if (response.status == 200) {
            this.isConfirmed = response.data.data['confirmed'] == 'Yes'
            if (this.isConfirmed) {
              this.$store.dispatch('session/setConfirmed')
            } else {
              this.$store.dispatch('session/unsetConfirmed')
            }
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadMeasurements() {
      this.switchNum++
      if (this.$router.currentRoute.name != 'measurementslist')
        this.$router.push('/measurements')
    },
    launch() {
      const isOpened = Gleap.isOpened()
      if (!isOpened) {
        Gleap.identify(this.userid, {
          name: this.displayName,
          email: this.userInfo.email
        })
        Gleap.open()
        this.toggle()
      }
    }
  },
  computed: {
    ...appConfig,
    ...builder
  }
}
</script>
<style scoped>
.copyright {
  z-index: 99;
  position: absolute;
  left: -8px;
  bottom: 0px;
}
</style>
