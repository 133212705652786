<template>
  <v-btn
    :plain="plain"
    :text="text"
    :outlined="outlined && !text"
    :title="title"
    :icon="round"
    :color="color"
    :class="myclass"
    @click="$emit('clickedThis')"
  >
    <v-icon :large="large" :medium="!large" class="mr-0">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    url: {
      type: String
    },
    icon: {
      type: String,
      default: 'mdi-content-save-all'
    },
    color: {
      type: String,
      default: 'accent'
    },
    myclass: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: true
    },
    plain: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'Save'
    }
  }
}
</script>
