<template>
  <v-btn
    icon
    tile
    text
    :large="large"
    :x-large="xlarge"
    :title="title"
    :color="color"
    ripple
    @click="$emit('clickedThis')"
    class="elevation-0"
  >
    <v-icon :title="title" :large="large" :x-large="xlarge" :color="iconcolor">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    url: {
      type: String
    },
    icon: {
      type: String,
      default: 'mdi-menu'
    },
    color: {
      type: String,
      default: 'transparent'
    },
    iconcolor: {
      type: String,
      default: 'silver'
    },
    large: {
      type: Boolean,
      default: false
    },
    xlarge: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Menu'
    }
  }
}
</script>
