import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)
const opts = {
  theme: {
    themes: {
      light: {
        primary: '#1f2c4c',
        secondary: '#F5F5F5',
        accent: '#d81b60',
        charcoal: '#212b31',
        paper: '#eaeaea',
        silver: '#9E9E9E',
        gold: '#D4AF37',
        bronze: '#CD7F32',
        disabled: '#dedede',
        lightHeader: '#fafafa',
        lightFooter: '#fafafa',
        lightTile: '#fdfdfd',
        progressActive: '#8BC34A',
        nicegreen: '#8BD44A',
        skyBlue: '#7BC6CC',
        turquoise: '#3ABAB4',
        softBlue: '#89CFF0',
        teal: '#008080',
        progressRest: '#1f2c4c',
        error: '#FF7777',
        green: '#087f23',
        lime: '#b5fe89',
        purpleAccent: '#F097DE',
        blueAccent: '#b0e8ff',
        pinkAccent: 'ff63c5',
        info: '#236DA7',
        success: '#53a653',
        hybrid: '#800080',
        circuit: '#FA7F2C',
        successgold: '#4BB543',
        warning: '#FFC107',
        background: '#FAFAFA',
        goldDark: '#4E4531',
        hybridDark: '#3E1F44',
        progressActiveDark: '#4A6B3D'
      }
    }
  }
}

export default new Vuetify(opts)
