<template>
  <v-text-field
    background-color="charcoal lighten-2 rounded-lg mt-2"
    class="text-h5"
    height="70"
    solo
    dark
    clearable
    searchCounter
    :label="searching ? 'Searching...' : 'Search ' + label"
    v-model="search"
    prepend-inner-icon="mdi-magnify"
    single-line
    hide-details
    @focus="$event.target.select()"
    @input="debouncedSearch"
  />
</template>
<script>
import util from '@/mixins/util.js'
export default {
  mixins: [util],
  data() {
    return {
      search: '',
      searching: false,
      backspace: false,
      debounce: null,
      loadedList: []
    }
  },
  beforeMount() {
    this.search = this.searchFor
    this.loadedList = this.items.slice()
  },

  watch: {
    searchFor(value) {
      this.search = value
      this.debouncedSearch()
    },
    searchCounter() {
      this.search = ''
    }
  },
  props: {
    searchCounter: {
      type: Number,
      default: 0
    },
    items: {
      type: Array
    },
    limit: {
      type: Number,
      default: 2
    },
    label: {
      type: String,
      default: 'Items'
    },
    searchIn: {
      type: Array,
      default: null
    },
    searchFor: {
      type: String,
      default: ''
    },
    dark: {
      default: false
    },
    what: {
      type: String,
      default: 'name'
    }
  },
  methods: {
    debouncedSearch() {
      this.searching = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if (this.searchIn != null) this.searchItems(this.items, this.searchIn)
        else this.searchItems(this.items, this.what)
      }, 500)
      this.searching = false
    },
    searchItems(myitems, what) {
      if (this.isEmpty(this.search) || this.search.length < this.limit) {
        this.$emit('search_results', this.loadedList)
        this.$emit('reset')
        this.$emit('searching', 'No')
        return
      }

      this.$emit('searching', 'Yes')

      const searchTerm = this.search.replace(/[^A-Za-z0-9]/gi, '').toLowerCase()

      const result = myitems.filter(item => {
        let target = ''
        // If an array of columns, concat values to search in
        if (Array.isArray(what)) {
          target = what
            .map(el => item[el] ?? '') // Use nullish coalescing to handle null/undefined
            .join('')
            .replace(/[^A-Za-z0-9]/gi, '')
            .toLowerCase()
        } else {
          target = (item[what] ?? '').replace(' ', '').toLowerCase()
        }

        return target.includes(searchTerm)
      })

      this.$emit('search_results', result)
    }
  }
}
</script>
