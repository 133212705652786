<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    :max-width="dialogWidth"
    persistent
    overlay-opacity="0.9"
  >
    <v-card class="d-flex flex-column charcoal noscroll-x" flat>
      <BaseCloseDialogHeader
        heading="Free Tier Limit"
        @cancel="$emit('cancel')"
      />
      <v-card flat class="transparent ">
        <v-card-text :class="textSize + ' paper--text'">
          This feature is limited in the free version.
          <v-spacer />
        </v-card-text>
        <v-card-text class="orange--text">
          <span :class="textSizeSmall + ' silver--text'">
            Free tier limit reached:
          </span>
          <v-spacer />
          {{ limitation }}
        </v-card-text>
      </v-card>
      <v-spacer />
      <v-card-text class="silver--text px-4">
        <span :class="textSizeXSmall + ' silver--text'">
          You can {{ remediation }} or
          <a
            @click="$router.push('/upgrade')"
            title="Get Pro"
            class="progressActive--text"
          >
            upgrade to PRO.
          </a>
        </span>
        <v-spacer />
      </v-card-text>
      <v-card-actions class=" pl-0 pr-2">
        <BaseActionButton
          label="GET PRO"
          icon="mdi-arrow-up-circle-outline"
          text
          class="charcoal progressActive--text"
          @clickedThis="getPro()"
        />
        <v-spacer />
        <BaseActionButton
          dark
          label="OK"
          text
          icon="mdi-checkbox-outline"
          color="silver"
          @clickedThis="$emit('cancel')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  name: 'UpgradeWarning',
  components: {},
  data() {
    return {
      invites: [],
      loaded: false,
      snackbar: false,
      snackText: '',
      timeout: 2000
    }
  },
  computed: {
    ...appConfig
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    limitation: {
      type: String,
      default: ''
    },
    remediation: {
      type: String,
      default: ''
    }
  },
  beforeMount() {},
  methods: {
    getPro() {
      this.upgrade()
      this.$emit('cancel')
    },
    capitalizeName(name) {
      if (name != null) name = name.charAt(0).toUpperCase() + name.slice(1)
    }
  }
}
</script>
