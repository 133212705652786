import moment from 'moment/src/moment'
import { mapGetters } from 'vuex'
import axios from 'axios'

export function defaultRepScheme() {
  return 'hzWVL'
}

export function dynamicSort(property) {
  var sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function(a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}
export function dynamicSortMultiple() {
  /*
   * save the arguments object as it will be overwritten
   * note that arguments object is an array-like object
   * consisting of the names of the properties to sort by
   */
  var props = arguments
  return function(obj1, obj2) {
    var i = 0,
      result = 0,
      numberOfProperties = props.length
    /* try getting a different result from 0 (equal)
     * as long as we have extra properties to compare
     */
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i])(obj1, obj2)
      i++
    }
    return result
  }
}

export default {
  computed: {
    marketingLink() {
      return 'https://bfitr.app'
    },
    appStoreLink() {
      return 'https://apps.apple.com/us/app/bfitr/id1631562520'
    },
    googlePlayLink() {
      return 'https://play.google.com/store/apps/details?id=com.bfitsquared.bfitr'
    },
    reqTimeout() {
      return 800
    },
    dietTolerance() {
      return 10
    },
    showGetPro() {
      return false
    },
    enableUpgrades() {
      return true
    },
    charcoalTile() {
      return 'background: linear-gradient(rgba(234,234,234,.025), rgba(234,234,234,.025)),  center center / cover no-repeat'
    },
    charcoalTileMenu() {
      return 'background: linear-gradient(rgba(234,234,234,.075), rgba(234,234,234,.05)),  center center / cover no-repeat'
    },
    charcoalTileLight() {
      return 'background: linear-gradient(rgba(255,234,234,.9), rgba(234,234,234,.4)),  center center / cover no-repeat'
    },
    pollingInterval() {
      return 60000
    },
    isPhone() {
      let val = false
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          val = true
          break
        case 'sm':
          val = true
          break
        case 'md':
          val = true
          break
        case 'lg':
          val = false
          break
        case 'xl':
          val = false
          break
      }
      return val
    },
    soundCutoff() {
      return 4
    },
    dialogWidth() {
      return 850
    },
    factor() {
      return 0.453592
    },

    circuitColor() {
      return 'circuit'
    },
    liftingColor() {
      return 'progressActive charcoal--text'
    },
    hybridColor() {
      return 'hybrid paper--text'
    },
    gradient() {
      return 'to bottom left, rgba(216,27,96,.1), rgb(31, 44, 76, .3)'
    },
    gradientText() {
      return 'to bottom left, rgba(216,27,96,.05), rgb(31, 44, 76, .025)'
    },
    textSizeXXXLarge() {
      return this.isPhone ? 'display-4' : 'display-5'
    },
    textSizeXXLarge() {
      return this.isPhone ? 'display-3' : 'display-4'
    },
    textSizeXLarge() {
      return this.isPhone ? 'display-2' : 'display-3'
    },
    textSizeLarge() {
      return this.isPhone ? 'display-1' : 'display-2'
    },
    textSize() {
      return this.isPhone ? 'title' : 'display-1'
    },
    textSizeSmall() {
      return this.isPhone ? 'body-1' : 'title'
    },
    textSizeXSmall() {
      return this.isPhone ? 'caption' : 'body-1'
    },
    getAlphabet() {
      let alphabet = []
      alphabet.push('all')
      for (let char of 'abcdefghijklmnopqrstuvwxyz') alphabet.push(char)
      return alphabet
    },

    ...mapGetters({
      userInfo: 'session/userInfo',
      useMetric: 'session/useMetric',
      is_pro: 'session/is_pro',
      subscription: 'session/subscription'
    })
  },
  methods: {
    upgrade() {
      if (this.isNativeApp()) this.$router.push('/upgradeapp')
      else this.$router.push('/upgrade')
    },
    localUpdate(item, newValue) {
      let exists = localStorage.getItem(item)
      if (exists) {
        localStorage.removeItem(item)
        localStorage.setItem(item, JSON.stringify(newValue))
      } else localStorage.setItem(item, JSON.stringify(newValue))
    },
    dietMatches(pc, pf, pp, c, diets) {
      let matches = []
      let atkFound = false

      diets.forEach(element => {
        let diff =
          Math.abs(element.carbs - pc) +
          Math.abs(element.proteins - pp) +
          Math.abs(element.fats - pf)
        diff = Math.round(diff / 3)
        element.diff = diff

        let carbLimitMatched = true
        if (element.carb_limit != null) {
          carbLimitMatched = c <= element.carb_limit
        }

        if (element.range) {
          if (
            element.carbs <= pc &&
            pc <= element.carbs_plus &&
            element.fats <= pf &&
            pf <= element.fats_plus &&
            element.proteins <= pp &&
            pp <= element.proteins_plus
          ) {
            element.diff = 0
            //only show one ATKINS match
            if (carbLimitMatched && !atkFound) {
              matches.push(element)
              atkFound = true
            }
          }
        } else if (diff <= this.dietTolerance) {
          if (carbLimitMatched) matches.push(element)
        }
      })

      matches.sort(dynamicSort('diff'))
      return matches
    },
    effortColor(effort) {
      if (effort >= 90) return 'accent'
      else if (effort >= 80) return 'gold'
      else if (effort >= 70) return 'success'
      else if (effort >= 60) return 'blue darken-2 paper--text'
      else if (effort >= 50) return 'silver darken-1'
      else return 'charcoal '
    },
    updatePackedWorkout(workout) {
      let available = localStorage.getItem('workouts_packed')
      if (available != null) {
        available = JSON.parse(available)
        available = available.map(el =>
          el.workoutid === workout.workoutid && workout.userid == this.userid
            ? workout
            : el
        )
        localStorage.setItem('workouts_packed', JSON.stringify(available))
      }
    },
    deletePackedWorkout(workout) {
      this.removeWorkout(workout, 'workouts_packed')
    },
    deleteSyncedWorkout(workout) {
      this.removeWorkout(workout, 'workouts_queued')
    },
    removeWorkout(workout, queue) {
      let available = localStorage.getItem(queue)
      if (available != null) {
        available = JSON.parse(available)
        available = available.filter(
          el =>
            el.workoutid !== workout.workoutid && workout.userid == this.userid
        )

        localStorage.setItem(queue, JSON.stringify(available))
      }
    },
    queueWorkout(workout) {
      let queued_workouts = localStorage.getItem('workouts_queued')
      if (queued_workouts) {
        queued_workouts = JSON.parse(queued_workouts)
      } else {
        queued_workouts = []
      }
      let exists = queued_workouts.find(
        el => el.workoutid == workout.workoutid && el.userid == this.userid
      )
      if (exists) {
        queued_workouts = queued_workouts.map(el =>
          el.workoutid === workout.workoutid && workout.userid == this.userid
            ? workout
            : el
        )
      } else {
        queued_workouts.push(workout)
      }
      localStorage.setItem('workouts_queued', JSON.stringify(queued_workouts))
    },
    syncWorkout(workout) {
      return axios
        .post(this.baseURL + '/workout/sync', {
          workout: workout
        })
        .then(response => {
          if (response.status == 200) {
            this.deleteSyncedWorkout(workout)
          }
        })
    },
    normalizeNutrients(item) {
      let normalized = Object.assign({})
      normalized['ENERC_KCAL'] = { quantity: item['ENERC_KCAL'] }
      normalized['PROCNT'] = { quantity: item['PROCNT'] }
      normalized['FAT'] = { quantity: item['FAT'] }
      normalized['CHOCDF'] = { quantity: item['CHOCDF'] }
      normalized['FIBTG'] = { quantity: item['FIBTG'] }
      normalized['Sugar.alcohol'] = { quantity: item['Sugar.alcohol'] }
      normalized['CHOCDF.net'] = { quantity: item['CHOCDF.net'] }
      normalized['FAMS'] = { quantity: item['FAMS'] }
      normalized['FAPU'] = { quantity: item['FAPU'] }
      normalized['FASAT'] = { quantity: item['FASAT'] }
      normalized['FATRN'] = { quantity: item['FATRN'] }
      normalized['FOLDFE'] = { quantity: item['FOLDFE'] }
      normalized['FOLFD'] = { quantity: item['FOLFD'] }
      normalized['FOLAC'] = { quantity: item['FOLAC'] }
      normalized['SUGAR'] = { quantity: item['SUGAR'] }
      normalized['SUGAR.added'] = { quantity: item['SUGAR.added'] }
      normalized['CHOLE'] = { quantity: item['CHOLE'] }
      normalized['NA'] = { quantity: item['NA'] }
      normalized['CA'] = { quantity: item['CA'] }
      normalized['FE'] = { quantity: item['FE'] }
      normalized['MG'] = { quantity: item['MG'] }
      normalized['K'] = { quantity: item['K'] }
      normalized['P'] = { quantity: item['P'] }
      normalized['ZN'] = { quantity: item['ZN'] }
      normalized['THIA'] = { quantity: item['THIA'] }
      normalized['RIBF'] = { quantity: item['RIBF'] }
      normalized['NIA'] = { quantity: item['NIA'] }
      normalized['VITA_RAE'] = { quantity: item['VITA_RAE'] }
      normalized['VITB6A'] = { quantity: item['VITB6A'] }
      normalized['VITB12'] = { quantity: item['VITB12'] }
      normalized['VITC'] = { quantity: item['VITC'] }
      normalized['VITD'] = { quantity: item['VITD'] }
      normalized['VITK1'] = { quantity: item['VITK1'] }
      normalized['TOCPHA'] = { quantity: item['TOCPHA'] }
      normalized['WATER'] = { quantity: item['WATER'] }

      return normalized
    },

    sumNutrients(items) {
      let sum = Object.assign({})
      sum['ENERC_KCAL'] = 0
      sum['PROCNT'] = 0
      sum['FAT'] = 0
      sum['CHOCDF'] = 0
      sum['FIBTG'] = 0
      sum['Sugar.alcohol'] = 0
      sum['CHOCDF.net'] = 0
      sum['FAMS'] = 0
      sum['FAPU'] = 0
      sum['FASAT'] = 0
      sum['FATRN'] = 0
      sum['FOLDFE'] = 0
      sum['FOLFD'] = 0
      sum['FOLAC'] = 0
      sum['SUGAR'] = 0
      sum['SUGAR.added'] = 0
      sum['CHOLE'] = 0
      sum['NA'] = 0
      sum['CA'] = 0
      sum['FE'] = 0
      sum['MG'] = 0
      sum['K'] = 0
      sum['P'] = 0
      sum['ZN'] = 0
      sum['THIA'] = 0
      sum['RIBF'] = 0
      sum['NIA'] = 0
      sum['VITA_RAE'] = 0
      sum['VITB6A'] = 0
      sum['VITB12'] = 0
      sum['VITC'] = 0
      sum['VITK1'] = 0
      sum['TOCPHA'] = 0
      sum['WATER'] = 0

      items.forEach(item => {
        sum['ENERC_KCAL'] =
          sum['ENERC_KCAL'] +
          parseFloat(item['ENERC_KCAL']) * parseFloat(item['fraction'])
        sum['PROCNT'] =
          sum['PROCNT'] +
          parseFloat(item['PROCNT']) * parseFloat(item['fraction'])
        sum['FAT'] =
          sum['FAT'] + parseFloat(item['FAT'] * parseFloat(item['fraction']))
        sum['CHOCDF'] =
          sum['CHOCDF'] +
          parseFloat(item['CHOCDF'] * parseFloat(item['fraction']))
        sum['FIBTG'] =
          sum['FIBTG'] +
          parseFloat(item['FIBTG'] * parseFloat(item['fraction']))
        sum['Sugar.alcohol'] =
          sum['Sugar.alcohol'] +
          parseFloat(item['Sugar.alcohol'] * parseFloat(item['fraction']))
        sum['CHOCDF.net'] =
          sum['CHOCDF.net'] +
          parseFloat(item['CHOCDF.net'] * parseFloat(item['fraction']))
        sum['FAMS'] =
          sum['FAMS'] + parseFloat(item['FAMS'] * parseFloat(item['fraction']))
        sum['FAPU'] =
          sum['FAPU'] + parseFloat(item['FAPU'] * parseFloat(item['fraction']))
        sum['FASAT'] =
          sum['FASAT'] +
          parseFloat(item['FASAT'] * parseFloat(item['fraction']))
        sum['FATRN'] =
          sum['FATRN'] +
          parseFloat(item['FATRN'] * parseFloat(item['fraction']))
        sum['FOLDFE'] =
          sum['FOLDFE'] +
          parseFloat(item['FOLDFE'] * parseFloat(item['fraction']))
        sum['FOLFD'] =
          sum['FOLFD'] +
          parseFloat(item['FOLFD'] * parseFloat(item['fraction']))
        sum['FOLAC'] =
          sum['FOLAC'] +
          parseFloat(item['FOLAC'] * parseFloat(item['fraction']))
        sum['SUGAR'] =
          sum['SUGAR'] +
          parseFloat(item['SUGAR'] * parseFloat(item['fraction']))
        sum['SUGAR.added'] =
          sum['SUGAR.added'] +
          parseFloat(item['SUGAR.added'] * parseFloat(item['fraction']))
        sum['CHOLE'] =
          sum['CHOLE'] +
          parseFloat(item['CHOLE'] * parseFloat(item['fraction']))
        sum['NA'] =
          sum['NA'] ?? 0 + parseFloat(item['NA'] * parseFloat(item['fraction']))
        sum['CA'] =
          sum['CA'] ?? 0 + parseFloat(item['CA'] * parseFloat(item['fraction']))
        sum['FE'] =
          sum['FE'] ?? 0 + parseFloat(item['FE'] * parseFloat(item['fraction']))
        sum['MG'] =
          sum['MG'] ?? 0 + parseFloat(item['MG'] * parseFloat(item['fraction']))
        sum['K'] =
          sum['K'] ?? 0 + parseFloat(item['K'] * parseFloat(item['fraction']))
        sum['P'] =
          sum['P'] ?? 0 + parseFloat(item['P'] * parseFloat(item['fraction']))
        sum['ZN'] =
          sum['ZN'] ?? 0 + parseFloat(item['ZN'] * parseFloat(item['fraction']))
        sum['THIA'] =
          sum['THIA'] + parseFloat(item['THIA'] * parseFloat(item['fraction']))
        sum['RIBF'] =
          sum['RIBF'] + parseFloat(item['RIBF'] * parseFloat(item['fraction']))
        sum['NIA'] =
          sum['NIA'] + parseFloat(item['NIA'] * parseFloat(item['fraction']))
        sum['VITA_RAE'] =
          sum['VITA_RAE'] +
          parseFloat(item['VITA_RAE'] * parseFloat(item['fraction']))
        sum['VITB6A'] =
          sum['VITB6A'] +
          parseFloat(item['VITB6A'] * parseFloat(item['fraction']))
        sum['VITB12'] =
          sum['VITB12'] +
          parseFloat(item['VITB12'] * parseFloat(item['fraction']))
        sum['VITC'] =
          sum['VITC'] + parseFloat(item['VITC'] * parseFloat(item['fraction']))
        sum['VITD'] =
          sum['VITD'] + parseFloat(item['VITD'] * parseFloat(item['fraction']))
        sum['VITK1'] =
          sum['VITK1'] +
          parseFloat(item['VITK1'] * parseFloat(item['fraction']))
        sum['TOCPHA'] =
          sum['TOCPHA'] +
          parseFloat(item['TOCPHA'] * parseFloat(item['fraction']))
        sum['WATER'] =
          sum['WATER'] +
          parseFloat(item['WATER'] * parseFloat(item['fraction']))
      })
      return sum
    },

    calculateCalories(mets, weight, minutes) {
      return this.twoDigits(((mets * 3.5 * weight) / 200) * minutes)
    },
    reloadBFit() {
      if (this.isIOS()) {
        let href = '/'
        window.location.href = href
      } else window.location.reload()
    },
    version() {
      //****************//
      return 'Zeta@3.3.4.9'
    },
    versionNum() {
      let version = parseInt(this.version().replace(/\D/g, ''))
      return version
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    getMeasureId(what) {
      let id = null
      this.measureList.forEach(el => {
        if (el.name == what) {
          id = el.id
        }
      })
      return id
    },
    isNativeApp() {
      return window.ReactNativeWebView != undefined
    },
    isAndroidSimple() {
      return window.navigator.userAgent.includes('Android')
    },
    isAndroid() {
      return this.isNativeApp() && this.isAndroidSimple()
    },
    isIOSSimple() {
      var iosQuirkPresent = function() {
        var audio = new Audio()
        audio.volume = 0.9
        return audio.volume === 1
      }
      var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
      var isAppleDevice = navigator.userAgent.includes('Macintosh')
      var isTouchScreen = navigator.maxTouchPoints >= 1
      return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()))
    },
    isIOS() {
      return this.isNativeApp() && this.isIOSSimple()
    },
    appLogout() {
      if (this.isNativeApp()) window.ReactNativeWebView.postMessage('LOGOUT')
    },
    debugNative(debug) {
      if (!this.isNativeApp()) return
      else {
        if (debug) window.ReactNativeWebView.postMessage('DEBUG_ON')
        else window.ReactNativeWebView.postMessage('DEBUG_OFF')
      }
    },
    extractJsonContent(response) {
      let jsonContent = null

      if (
        response.data.choices &&
        response.data.choices[0].message &&
        response.data.choices[0].message.content
      ) {
        jsonContent = response.data.choices[0].message.content
      } else if (
        response.data[0] &&
        response.data[0].message &&
        response.data[0].message.content
      ) {
        jsonContent = response.data[0].message.content
      } else {
        return null;
      }

      return jsonContent
    },
    requestOffering() {
      if (this.isNativeApp())
        window.ReactNativeWebView.postMessage('GET_OFFERING')
    },
    requestSubscription(sku) {
      if (this.isNativeApp()) {
        let msg = 'REQUEST_SUBSCRIPTION:' + JSON.stringify(sku)
        window.ReactNativeWebView.postMessage(msg)
      }
    },
    playRestNative() {
      window.ReactNativeWebView.postMessage('PLAY_REST')
    },
    playActiveNative() {
      window.ReactNativeWebView.postMessage('PLAY_ACTIVE')
    },
    playDingNative() {
      window.ReactNativeWebView.postMessage('PLAY_DING')
    },
    playSwitchNative() {
      window.ReactNativeWebView.postMessage('PLAY_SWITCH')
    },
    playStartNative() {
      window.ReactNativeWebView.postMessage('PLAY_START')
    },
    playEndNative() {
      window.ReactNativeWebView.postMessage('PLAY_END')
    },
    feedLoaded() {
      if (window.ReactNativeWebView != undefined)
        window.ReactNativeWebView.postMessage('FEED_LOADED')
    },
    enableBarcodeScan() {
      if (window.ReactNativeWebView != undefined)
        window.ReactNativeWebView.postMessage('SCAN')
    },
    disableBarcodeScan() {
      if (window.ReactNativeWebView != undefined)
        window.ReactNativeWebView.postMessage('CANCEL_SCAN')
    },

    scanWorkoutQR() {
      if (window.ReactNativeWebView != undefined)
        window.ReactNativeWebView.postMessage('SCAN_WORKOUT_QR')
    },
    cancelWorkoutQR() {
      if (window.ReactNativeWebView != undefined)
        window.ReactNativeWebView.postMessage('CANCEL_WORKOUT_QR')
    },
    screenOff() {
      if (window.ReactNativeWebView != undefined)
        window.ReactNativeWebView.postMessage('SLEEP')
    },
    screenOn() {
      if (window.ReactNativeWebView != undefined) {
        window.ReactNativeWebView.postMessage('NOSLEEP')
      }
    },
    initials(display_name) {
      let upper = display_name.toUpperCase()
      return upper.substring(0, 2)
    },
    inMinutes(end, start) {
      let minutes = moment(end, 'YYYY-MM-DD HH:mm:ss').diff(
        moment(start, 'YYYY-MM-DD HH:mm:ss'),
        'minutes'
      )
      return minutes
    },
    canEdit(object) {
      return this.xvy || object.userid == this.userid
    },
    badgeContent(workout) {
      if (workout.type == 'Lifting') return workout.rounds + ' rounds'
      if (workout.type == 'Hybrid') {
        if (workout.xmode == 'amrap')
          return Math.floor(workout.total_time) + ' minutes - AMRAP'
        if (workout.xmode == 'time')
          return (
            workout.rounds +
            ' round(s) for time, max ' +
            workout.max_time +
            ' minutes'
          )

        if (workout.xmode == 'emom')
          return (
            workout.rounds +
            ' round(s) for a total of ' +
            Math.floor(workout.total_time / 60) +
            ' minutes - EMOM'
          )
      }
      if (workout.type == 'Circuit') {
        return (
          workout.rounds +
          ' round(s) for a total of ' +
          Math.floor(workout.total_time) +
          ' minutes'
        )
      }
    },
    exerciseInfo(exercise) {
      let val = ''
      if (exercise.track_distance == 'Yes') {
        val =
          exercise.distance_metric +
          ' Km or ' +
          this.twoDigits(exercise.distance_imperial) +
          ' miles '
      } else if (exercise.track_time == 'Yes') {
        val = exercise.time + ' seconds'
      } else if (exercise.weight > 0)
        val =
          exercise.reps +
          ' reps @RX ' +
          exercise.weight +
          ' (Male), ' +
          exercise.weightf +
          ' (Female)'
      else val = exercise.sets + ' sets'
      return val
    },
    bottomVisible() {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight - 100
      const bottomOfPage = visible + scrollY >= pageHeight
      return bottomOfPage || pageHeight < visible
    },
    toast(what, expires = 1000, type = 'success') {
      this.$store.dispatch(
        'notification/pushNotification',
        { text: what, expires: expires, reset: true, type: type },
        {
          root: true
        }
      )
    },

    workoutTypeColor(workout) {
      if (workout.type == 'Lifting' || workout.name == 'Lifting')
        return this.liftingColor
      else if (workout.type == 'Circuit' || workout.name == 'Circuit')
        return this.circuitColor
      else if (workout.type == 'Hybrid' || workout.name == 'Hybrid')
        return this.hybridColor
      else return 'silver'
    },
    generateCode(name) {
      name = name.toLowerCase()
      name = name.replaceAll(' - ', '-')
      name = name.replaceAll(' ', '-')
      name = name.replaceAll('(', '')
      name = name.replaceAll(')', '')
      name = name.replaceAll(',', '')
      name = name.replaceAll(':', '')
      name = name.replaceAll('.', '')

      return name.replaceAll(/[^a-zA-Z0-9-]/g, '')
    },
    capitalize(word) {
      if (word) return word.charAt(0).toUpperCase() + word.slice(1)
    },
    resizedName(str, size) {
      return str.concat('/w=', size)
    },
    cdn(what, file) {
      return 'https://s.bfit.fit/assets/' + what + '/' + file
    },
    weightUnit(metric) {
      return metric ? 'Kg' : 'lbs'
    },
    isEmpty(what) {
      if (what == 'undefined' || what == null) return true
      else return JSON.stringify(what) == '{}'
    },
    nvl(object, value) {
      if (typeof object != 'undefined') return object
      else return value
    },
    calculateRM(reps, weight, metric) {
      let rm = 0
      if (reps == 0 || weight == 0) return 0
      if (reps == 1) rm = weight
      else rm = weight * (1 + reps / 30)
      return this.getRMObject(rm, metric)
    },
    getRMObject(val, metric) {
      let factor = this.factor
      let rm = {}
      rm.oneRM = val
      if (metric) {
        rm.oneRMImperial = rm.oneRM / factor
        rm.oneRM = Math.floor(rm.oneRM * 100) / 100
        rm.oneRMFive = Math.round(rm.oneRM / 5) * 5
        rm.oneRMTen = Math.round(rm.oneRM / 10) * 10

        rm.oneRMMetric = rm.oneRM
        rm.oneRMFiveMetric = rm.oneRMFive
        rm.oneRMImperial = Math.floor(rm.oneRMImperial * 100) / 100
        rm.oneRMFiveImperial = Math.round(rm.oneRMImperial / 5) * 5
      } else {
        rm.oneRMMetric = rm.oneRM * factor
        rm.oneRM = Math.floor(rm.oneRM * 100) / 100
        rm.oneRMFive = Math.round(rm.oneRM / 5) * 5
        rm.oneRMTen = Math.round(rm.oneRM / 10) * 10

        rm.oneRMMetric = Math.floor(rm.oneRMMetric * 100) / 100
        rm.oneRMFiveMetric = Math.round(rm.oneRMMetric / 5) * 5

        rm.oneRMImperial = rm.oneRM
        rm.oneRMFiveImperial = rm.oneRMFive
      }
      return rm
    },
    shortenTitle(title) {
      if (title.length > 29) return title.substring(0, 28) + '...'
      else return title
    },

    strengthColor(value, text) {
      let val = ''
      if (value == 'Decent') val = 'teal '
      else if (value == 'Good') val = 'success '
      else if (value == 'Advanced') val = 'purpleAccent '
      else if (value == 'Optimal') val = 'progressActive '
      else if (value == 'Elite') val = 'pinkAccent '
      else val = 'silver '
      if (text) {
        val = val.replace(' ', '--text ')
        val = val.replace('darken', 'text-darken')
        val = val.replace('lighten', 'text-lighten')
      }
      return val
    },
    dificultyColor(value, text) {
      let val = ''
      if (value == 'Beginner') val = 'teal '
      else if (value == 'Intermediate') val = 'gold '
      else if (value == 'Advanced') val = 'orange darken-1'
      else val = 'accent '
      if (text) {
        val = val.replace(' ', '--text ')
        val = val.replace('darken', 'text-darken')
        val = val.replace('lighten', 'text-lighten')
      }
      return val
    },
    iconNumber(index) {
      let idx = index + 1
      let result = ''
      if (idx < 11) {
        result = 'mdi-numeric-' + idx + '-circle-outline'
      } else if (idx < 20) {
        result = 'mdi-dots-horizontal-circle-outline'
      } else {
        result = 'mdi-dots-horizontal-circle-outline'
      }

      return result
    },
    fiveDigits(what) {
      return Math.floor(what * 100000) / 100000
    },
    threeDigits(what) {
      return Math.floor(what * 1000) / 1000
    },
    twoDigits(what) {
      return Math.floor(what * 100) / 100
    },
    oneDigit(what) {
      return Math.floor(what * 10) / 10
    },
    roundFive(x) {
      return parseInt(Math.floor(x / 5)) * 5
    },
    roundToNearest2Point5(x) {
      return Math.floor(x / 2.5) * 2.5
    },
    unit(metric) {
      return metric ? 'Kg' : 'lbs'
    },
    distanceUnit(metric) {
      return metric ? 'km' : 'miles'
    },
    kmToMiles() {
      return 0.62137119
    },
    trimName(workout) {
      let len = workout.name.length
      let val = workout.name
      if (workout.adhoc == 'Yes') val = workout.name.substring(0, len - 3)
      return val
    },
    trimString(val, max) {
      let len = val.length
      if (len > max) {
        val = val.substring(0, max)
        val = val + '...'
      }
      return val
    },
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY')
    },
    mysqlDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    formatDateTime(date) {
      // if passed in date is not correct, put current date an time
      if (!moment(date).isValid()) {
        return moment().format('YYYY-MM-DD HH:mm')
      }
      return moment(date).format('YYYY-MM-DD HH:mm')
    },

    prettyDateTime(date) {
      return moment(date, 'YYYY-MM-DD  HH:mm:ss').format('MMMM Do YYYY, h:mm A')
    },
    prettyDate(date) {
      return moment(date, 'YYYY-MM-DD  HH:mm:ss').format('MMMM Do, YYYY')
    },
    prettyShortDate(date) {
      return moment(date, 'YYYY-MM-DD  HH:mm:ss').format('MMM. Do, YYYY')
    },
    shortDate(date) {
      return moment(date, 'YYYY-MM-DD').format('MMMM Do, YYYY')
    },
    prettyTime(date) {
      return moment(date, 'YYYY-MM-DD  HH:mm:ss').format('h:mm A')
    },
    fromNow(date) {
      return moment(date, 'YYYY-MM-DD  HH:mm:ss').fromNow()
    },
    now(offset = 0, dateonly = false) {
      let format = 'YYYY-MM-DD HH:mm:ss'
      if (dateonly) format = 'YYYY-MM-DD'
      if (offset == 0) return moment().format(format)
      else {
        return moment()
          .add(offset, 'days')
          .format(format)
      }
    },
    nowOffset(offset) {
      let time = moment(new Date()).subtract(offset, 'minutes')
      return time.format('YYYY-MM-DD  HH:mm:ss')
    },
    dayOfWeek() {
      return moment().format('dddd')
    },
    prettyNow() {
      return moment().format('MMMM Do YYYY, h:mm:ss A')
    },
    isValidDate(date) {
      return moment(date).isValid()
    },
    prettyNumber(num) {
      return parseFloat(num).toLocaleString('en-US', {
        maximumFractionDigits: 2
      })
    },
    arrayMin(array) {
      let min = 0
      if (array.length == 0) return 0
      if (array.length == 1) return array[0]

      min = parseFloat(
        array.reduce(function(a, b) {
          return Math.min(a, b)
        })
      )
      return min
    },
    arrayMax(array) {
      let max = 0
      if (array.length == 0) return 0
      if (array.length == 1) return array[0]

      max = parseFloat(
        array.reduce(function(a, b) {
          return Math.max(a, b)
        })
      )
      return max
    },
    arrayAvg(array) {
      var i = 0,
        sum = 0,
        len = array.length
      while (i < len) {
        sum = sum + array[i++]
      }
      return this.twoDigits(sum / len)
    }
  }
}
