import Vue from 'vue'
import Vuex from 'vuex'
import * as config from '@/store/modules/config.js'
import * as session from '@/store/modules/session.js'
import * as notification from '@/store/modules/notification.js'
import * as builder from '@/store/modules/builder.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    config,
    session,
    notification,
    builder
  },
  state: {
    selectedExercises: [],
    debug: true
  },

  getters: {
    loggedIn(state) {
      return state.session.user != null && state.session.user.token != ''
    },
    showMenu(state) {
      return state.session.menu
    },
    xvy(state) {
      if (state.session.user) return state.session.user.data.xvy == 'Yes'
    },
    userid(state) {
      if (state.session.user.token != '') return state.session.user.data.id
      else return 'anon'
    },
    gender(state) {
      return state.session.user.data.gender
    },
    sex(state) {
      return state.session.user.data.sex
    },
    confirmed(state) {
      if (state.session.user) return state.session.user.data.confirmed == 'Yes'
    },
    disabled(state) {
      return state.session.user.data.disabled
    },
    locked(state) {
      return state.session.user.data.locked
    },
    timezone(state) {
      return state.session.user.data.timezone
    },
    displayName(state) {
      return state.session.user.data.display_name
    },
    fullName(state) {
      return state.session.user.data.fullname
    },
    profilePic(state) {
      return state.session.user.data.profile_pic
    },
    baseURL(state) {
      return state.config.baseURL
    },
    isModalOpen(state) {
      return parseInt(state.session.modals) > 0
    }
  }
})
