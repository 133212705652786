import axios from 'axios'
import Vue from 'vue'
export const namespaced = true
export const state = {
  menu: false,
  modals: 0,
  subscription: null,
  user: {
    data: {
      fname: '',
      lname: '',
      mname: '',
      fullname: '',
      display_name: '',
      use_metric: '',
      timezone: '',
      email: '',
      profile_pic: '',
      gender: '',
      sex: '',
      confirmed: 'No',
      disabled: '',
      locked: '',
      id: '',
      xvy: 'No'
    },
    token: ''
  },
  errors: []
}


export const mutations = {
  NATIVE_AUTOLOGIN(state, userData) {
    state.user = userData
    state.errors = {}
    Vue.$cookies.set('user', state.user, '350d')
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${state.user.token}`

    return axios
      .post(this.state.config.baseURL + '/accounts/autologin', {
        token: userData.token
      })
      .then(response => {
        if (response.status == 200) {
          null
        } else {
          state.errors = {}
          state.user = null
          this.appLogout()
          Vue.$cookies.remove('user')
          axios.defaults.headers.common['Authorization'] = ''

          return
        }
      })
  },
  AUTOLOGIN(state, userData) {
    state.user = userData
    state.errors = {}
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${state.user.token}`
  },
  SET_USER_DATA(state, userData) {
    state.user = userData
    state.errors = {}
    Vue.$cookies.set('user', state.user, '350d')
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${state.user.token}`
  },
  UPDATE_USER(state, accountInfo) {
    const userData = {
      token: state.user.token,
      data: {
        fname: accountInfo.fname,
        lname: accountInfo.lname,
        fullname: accountInfo.fullname,
        display_name: accountInfo.display_name,
        use_metric: accountInfo.use_metric,
        timezone: accountInfo.timezone,
        email: accountInfo.username,
        profile_pic: accountInfo.profile_pic,
        gender: accountInfo.gender,
        sex: accountInfo.sex,
        confirmed: accountInfo.confirmed,
        disabled: accountInfo.disabled,
        subscription: accountInfo.subscription,
        locked: accountInfo.locked,
        id: accountInfo.id,
        xvy: accountInfo.xvy
      }
    }
    state.user = userData
    state.errors = []
    Vue.$cookies.set('user', state.user, '350d')
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${state.user.token}`
  },
  CLEAR_USER_DATA(state) {
    state.errors = {}
    state.user = null
    state.subscription = null
    Vue.$cookies.remove('user')
    return axios.post(this.state.config.baseURL + '/users/logout').then(() => {
      axios.defaults.headers.common['Authorization'] = ''
    })
  },
  TOGGLE_MODAL(state, up) {
    if (up) state.modals++
    else {
      if (state.modals > 0) state.modals--
    }
    if (window.ReactNativeWebView != undefined) {
      if (up) window.ReactNativeWebView.postMessage('MODAL_ON')
      else window.ReactNativeWebView.postMessage('MODAL_OFF')
    }
  },
  RESET_MODALS(state) {
    state.modals = 0
  },
  TOGGLE_MENU(state) {
    state.menu = !state.menu
  },
  SET_SEX(state, sex) {
    state.user.data.sex = sex
    Vue.$cookies.set('user', state.user, '350d')
  },
  SET_GENDER(state, gender) {
    state.user.data.sex = gender
    Vue.$cookies.set('user', state.user, '350d')
  },
  SET_CONFIRMED(state) {
    state.user.data.confirmed = 'Yes'
    Vue.$cookies.set('user', state.user, '350d')
  },
  UNSET_CONFIRMED(state) {
    state.user.data.confirmed = 'No'
    Vue.$cookies.set('user', state.user, '350d')
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription
  },
  SET_UNIT(state, metric) {
    state.user.data.use_metric = metric
    Vue.$cookies.set('user', state.user, '350d')
  },
  INITIALIZE_MENU(state, initial) {
    state.menu = initial
  }
}

export const actions = {
  login({ commit }, accountInfo) {
    if (accountInfo != null) {
      commit('SET_USER_DATA', accountInfo)
      this.loadSubscription
    } else {
      this.state.user = null
    }
  },

  loadSubscription({ commit }) {
    {
      return axios
        .get(this.state.config.baseURL + '/subscription', {})
        .then(response => {
          if (response.status == 200) {
            let subscription = { package: 'FREE', type: null, is_pro: null }
            if (response.data.data) subscription = response.data.data
            commit('SET_SUBSCRIPTION', subscription)
          } else {
            this.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  },

  autoLogin({ commit }, userData) {
    commit('AUTOLOGIN', userData)
  },
  nativeAutoLogin({ commit }, userData) {
    commit('NATIVE_AUTOLOGIN', userData)
  },
  logout({ commit }) {
    commit('CLEAR_USER_DATA')
  },
  updateUser({ commit }, accountInfo) {
    commit('UPDATE_USER', accountInfo)
  },
  setConfirmed({ commit }) {
    commit('SET_CONFIRMED')
  },
  setSex({ commit }, sex) {
    commit('SET_SEX', sex)
  },
  setGender({ commit }, gender) {
    commit('SET_GENDER', gender)
  },
  unsetConfirmed({ commit }) {
    commit('UNSET_CONFIRMED')
  },
  setUnit({ commit }, metric) {
    commit('SET_UNIT', metric)
  },
  toggleMenu({ commit }) {
    commit('TOGGLE_MENU')
  },
  toggleModal({ commit }, up) {
    commit('TOGGLE_MODAL', up)
  },
  resetModals({ commit }) {
    commit('RESET_MODALS')
  },
  initializeMenu({ commit }, initial) {
    commit('INITIALIZE_MENU', initial)
  }
}

export const getters = {
  userInfo: state => {
    if (state.user) return state.user.data
    else return null
  },
  useMetric: state => {
    if (state.user) return state.user.data.use_metric == 'Yes'
    else return false
  },
  is_pro: state => {
    if (state.subscription) return state.subscription.is_pro == 'Yes'
    else return false
  },
  subscription: state => {
    return state.subscription
  }
}

export default {
  state,
  getters,
  actions
}
